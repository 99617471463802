import React, { Fragment, useContext, useEffect, useState } from "react";
import "../css/header.css";
import logo from "./../img/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import LanguageContext from "../store/language-context";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import SvgLogo from "../icons/Logo";
import SvgDotSoft from "../icons/DotSoft";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);

  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);

    return () => window.removeEventListener("resize", updatePredicate);
  }, []);

  const updatePredicate = () => setIsMobile(window.innerWidth <= 1220);

  const openSidebar = () => setIsOpened((prev) => !prev);

  return (
    <div className="header-container">
      {!isMobile ? (
        <Fragment>
          <div className="header-container__navigation">
            <img
              src={logo}
              alt=""
              onClick={() => {
                scroll.scrollToTop();
              }}
            />

            {/*<SvgLogo />*/}

            <div>
              <span>
                <Link
                  to="functionality-content maxWidth__class"
                  smooth={true}
                  duration={1500}
                  offset={-100}
                >
                  {t("Λειτουργίες")}
                </Link>
              </span>
              <span>
                <Link
                  to="left-right-flex  functionality-content  maxWidth__class"
                  smooth={true}
                  duration={2000}
                  offset={-60}
                >
                  {t("Υποσυστήματα")}
                </Link>
              </span>

              {/*<span>*/}
              {/*  <Link to="energy" smooth={true} duration={1500} offset={-100}>*/}
              {/*    {t("Ενεργειακή Παρακολούθηση")}*/}
              {/*  </Link>*/}
              {/*</span>*/}

              <span>
                <a
                  href={"https://www.smartiscity.gr"}
                  target={"_blank"}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  {t("Εγκαταστάσεις")}
                </a>
              </span>

              <span
                onClick={() => {
                  scroll.scrollToBottom();
                }}
              >
                {t("Επικοινωνία")}
              </span>
            </div>
            <div className={"logos_container"}>
              <a
                href="https://www.smartiscity.gr"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://site.smartiscity.gr/wp-content/uploads/2021/06/smartiscity_logo.png"
                  alt={"img"}
                />
              </a>

              <a href="https://dotsoft.gr/" target="_blank" rel="noreferrer">
                {/*<img*/}
                {/*  style={{ width: 100 + "%", height: 100 + "%" }}*/}
                {/*  src="https://www.smartiscity.gr/wp-content/uploads/2021/10/dotsoft_80x38.png"*/}
                {/*  alt={"img"}*/}
                {/*/>*/}
                <SvgDotSoft />
              </a>
            </div>
          </div>

          {/*<div className={"header-language  language-style"}>*/}
          {/*  <span*/}
          {/*    onClick={() => {*/}
          {/*      i18next*/}
          {/*        .changeLanguage("el")*/}
          {/*        .catch((error) => console.warn(error));*/}
          {/*      languageCtx.languageCodeHandler("el");*/}
          {/*    }}*/}
          {/*    className={*/}
          {/*      languageCtx.languageCode === "el"*/}
          {/*        ? "language-style--active"*/}
          {/*        : null*/}
          {/*    }*/}
          {/*  >*/}
          {/*    EL*/}
          {/*  </span>*/}
          {/*  <strong>{" | "}</strong>*/}
          {/*  <span*/}
          {/*    onClick={() => {*/}
          {/*      i18next*/}
          {/*        .changeLanguage("en")*/}
          {/*        .catch((error) => console.warn(error));*/}
          {/*      languageCtx.languageCodeHandler("en");*/}
          {/*    }}*/}
          {/*    className={*/}
          {/*      languageCtx.languageCode === "en"*/}
          {/*        ? "language-style--active"*/}
          {/*        : null*/}
          {/*    }*/}
          {/*  >*/}
          {/*    EN*/}
          {/*  </span>*/}
          {/*</div>*/}
        </Fragment>
      ) : (
        <div className="header-container__navigation-mobile">
          <SvgLogo />
          <MenuIcon className="menu" onClick={openSidebar} />
          <div className={isOpened ? "open-sidebar" : "close-sidebar"}>
            <h4>MENU</h4>
            <hr />
            <span>
              <Link
                to="functionality-description-3"
                smooth={true}
                duration={2000}
              >
                {t("Χαρακτηριστικά")}
              </Link>
            </span>
            <hr />
            <span>
              <a
                href={"https://www.smartiscity.gr"}
                target={"_blank"}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {t("Εγκαταστάσεις")}
              </a>
            </span>
            <hr />
            <span>
              <Link to="functionality" smooth={true} duration={1500}>
                {t("Λειτουργίες")}
              </Link>
            </span>
            <hr />
            {/*<span*/}
            {/*  onClick={() => {*/}
            {/*    scroll.scrollToBottom();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {t("Ενεργειακή Παρακολούθηση")}*/}
            {/*</span>*/}
            {/*<hr />*/}
            <span
              onClick={() => {
                scroll.scrollToBottom();
              }}
            >
              {t("Επικοινωνία")}
            </span>
            <hr />
            {/*<div className={"language-style"}>*/}
            {/*  <span*/}
            {/*    onClick={() => {*/}
            {/*      i18next*/}
            {/*        .changeLanguage("el")*/}
            {/*        .catch((error) => console.warn(error));*/}
            {/*      languageCtx.languageCodeHandler("el");*/}
            {/*    }}*/}
            {/*    className={*/}
            {/*      languageCtx.languageCode === "el"*/}
            {/*        ? "language-style--active"*/}
            {/*        : null*/}
            {/*    }*/}
            {/*  >*/}
            {/*    EL*/}
            {/*  </span>*/}
            {/*  <span*/}
            {/*    onClick={() => {*/}
            {/*      i18next*/}
            {/*        .changeLanguage("en")*/}
            {/*        .catch((error) => console.warn(error));*/}
            {/*      languageCtx.languageCodeHandler("en");*/}
            {/*    }}*/}
            {/*    className={*/}
            {/*      languageCtx.languageCode === "en"*/}
            {/*        ? "language-style--active"*/}
            {/*        : null*/}
            {/*    }*/}
            {/*  >*/}
            {/*    EN*/}
            {/*  </span>*/}
            {/*</div>*/}
            {/*<hr />*/}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

// import React, { useContext, useEffect, useState } from "react";
// import "../css/header.css";
// // import logo from "../img/gis_logo.png";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Link } from "react-scroll";
// import { animateScroll as scroll } from "react-scroll";
// import LanguageContext from "../store/language-context";
// import i18next from "i18next";
// import { useTranslation } from "react-i18next";
// import SvgLogo from "../icons/Logo";
//
// const Header = () => {
//   const [isMobile, setIsMobile] = useState(false);
//   const [isOpened, setIsOpened] = useState(false);
//
//   const { t } = useTranslation();
//   const languageCtx = useContext(LanguageContext);
//
//   useEffect(() => {
//     updatePredicate();
//     window.addEventListener("resize", updatePredicate);
//
//     return () => window.removeEventListener("resize", updatePredicate);
//   }, []);
//
//   const updatePredicate = () => setIsMobile(window.innerWidth <= 1000);
//
//   const openSidebar = () => setIsOpened((prev) => !prev);
//
//   return (
//     <div className="header-container">
//       {!isMobile ? (
//         <div>
//           <div className="header-container__navigation">
//             {/*<img*/}
//             {/*  src={logo}*/}
//             {/*  alt=""*/}
//             {/*  onClick={() => {*/}
//             {/*    scroll.scrollToTop();*/}
//             {/*  }}*/}
//             {/*/>*/}
//             <SvgLogo />
//             <span>
//               <Link to="functionality" smooth={true} duration={1500}>
//                 {t("Βασικές Λειτουργίες")}
//               </Link>
//             </span>
//             <span>
//               <Link
//                 to="functionality-description-3"
//                 smooth={true}
//                 duration={2000}
//               >
//                 {t("Πλεονεκτήματα")}
//               </Link>
//             </span>
//             <span
//               onClick={() => {
//                 scroll.scrollToBottom();
//               }}
//             >
//               {t("Επικοινωνία")}
//             </span>
//             <div
//               style={{
//                 width: 80 + "px",
//                 position: "absolute",
//                 right: -110 + "%",
//                 top: -60 + "%",
//               }}
//             >
//               <a href="https://dotsoft.gr/" target="_blank" rel="noreferrer">
//                 <img
//                   style={{ width: 100 + "%", height: 100 + "%" }}
//                   src="https://www.smartiscity.gr/wp-content/uploads/2021/10/dotsoft_80x38.png"
//                   alt={"img"}
//                 />
//               </a>
//             </div>
//           </div>
//           <div
//             style={{
//               width: 80 + "px",
//               position: "absolute",
//               right: 15 + "%",
//               top: 30 + "%",
//             }}
//           >
//             <a href="https://dotsoft.gr/" target="_blank" rel="noreferrer">
//               <img
//                 style={{ width: 100 + "%", height: 100 + "%" }}
//                 src="https://www.smartiscity.gr/wp-content/uploads/2021/10/dotsoft_80x38.png"
//                 alt={"img"}
//               />
//             </a>
//           </div>
//           <div className={"header-language  language-style"}>
//             <span
//               onClick={() => {
//                 i18next
//                   .changeLanguage("el")
//                   .catch((error) => console.warn(error));
//                 languageCtx.languageCodeHandler("el");
//               }}
//               className={
//                 languageCtx.languageCode === "el"
//                   ? "language-style--active"
//                   : null
//               }
//             >
//               EL
//             </span>
//             <strong>{" | "}</strong>
//             <span
//               onClick={() => {
//                 i18next
//                   .changeLanguage("en")
//                   .catch((error) => console.warn(error));
//                 languageCtx.languageCodeHandler("en");
//               }}
//               className={
//                 languageCtx.languageCode === "en"
//                   ? "language-style--active"
//                   : null
//               }
//             >
//               EN
//             </span>
//           </div>
//         </div>
//       ) : (
//         <div className="header-container__navigation-mobile">
//           <img src={logo} alt="" />
//           <MenuIcon className="menu" onClick={openSidebar} />
//           <div className={isOpened ? "open-sidebar" : "close-sidebar"}>
//             <h4>MENU</h4>
//             <hr />
//             <span>
//               <Link to="functionality" smooth={true} duration={1500}>
//                 {t("Βασικές Λειτουργίες")}
//               </Link>
//             </span>
//             <hr />
//             <span>
//               <Link
//                 to="functionality-description-3"
//                 smooth={true}
//                 duration={2000}
//               >
//                 {t("Πλεονεκτήματα")}
//               </Link>
//             </span>
//             <hr />
//             <span
//               onClick={() => {
//                 scroll.scrollToBottom();
//               }}
//             >
//               {t("Επικοινωνία")}
//             </span>
//             <hr />
//             <div className={"language-style"}>
//               <span
//                 onClick={() => {
//                   i18next
//                     .changeLanguage("el")
//                     .catch((error) => console.warn(error));
//                   languageCtx.languageCodeHandler("el");
//                 }}
//                 className={
//                   languageCtx.languageCode === "el"
//                     ? "language-style--active"
//                     : null
//                 }
//               >
//                 EL
//               </span>
//               <span
//                 onClick={() => {
//                   i18next
//                     .changeLanguage("en")
//                     .catch((error) => console.warn(error));
//                   languageCtx.languageCodeHandler("en");
//                 }}
//                 className={
//                   languageCtx.languageCode === "en"
//                     ? "language-style--active"
//                     : null
//                 }
//               >
//                 EN
//               </span>
//             </div>
//             <hr />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };
//
// export default Header;

import * as React from "react";

const SvgInterfer = (props) => (
  <svg
    id="Interfer_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 223.66 247.64"
    {...props}
  >
    <defs>
      <style>
        {
          ".Interfer_svg__cls-1,.Interfer_svg__cls-4{fill:#5a6e7f}.Interfer_svg__cls-1,.Interfer_svg__cls-2{fill-rule:evenodd}.Interfer_svg__cls-2{fill:#97d5c9}.Interfer_svg__cls-3{fill:none}"
        }
      </style>
    </defs>
    <path
      className="Interfer_svg__cls-1"
      d="M16.14 116.89c1.36 16.79 9.93 31.17 12.7 35.15a46 46 0 0 0 10.08 10.9c9.15 6.82 20.21 8.29 31.27 8.78 9.12.4 18-.26 26.7-3.25 18.65-6.42 30.3-22.45 31.47-42a61.6 61.6 0 0 0-1.88-19.31C120 82.08 95.21 58.38 68.33 59.39c-10.55.39-20.16 6-28.22 12.41-14.41 11.46-25.49 26.29-23.97 45.09Z"
    />
    <path
      className="Interfer_svg__cls-2"
      d="M102.87 92.32c14.92-4.28 28.33-7.72 35.31-.09 12 13.08 22.35 32.64 43.66 29.15a23.8 23.8 0 0 0 10.1-4.27c17.19-12.71 12.19-37.45 3.18-53.44-11.34-20.13-32-33.91-53.71-40.42-12.4-3.71-26.64-3.93-38.5 1.77C86.42 33 73.82 49.9 75.75 68.64c1.57 15.24 11.77 28.1 27.12 23.68Z"
    />
    <path
      className="Interfer_svg__cls-3"
      d="M157.94 10.6h-2.82l2.82 2.82V10.6zM94.56 23.73l50.24 50.24h8.94L94.56 14.79v8.94zM157.94 15.01l-4.42-4.41h-8.94l13.36 13.35v-8.94zM157.94 25.54 142.99 10.6h-8.94l23.89 23.88v-8.94zM94.56 34.27l39.71 39.7h8.94L94.56 25.33v8.94zM100.86 10.6h-6.3v2.6l60.77 60.77h2.61v-6.3L100.86 10.6zM157.94 57.14 111.39 10.6h-8.94l55.49 55.48v-8.94zM157.94 36.08 132.46 10.6h-8.94l34.42 34.42v-8.94zM94.56 44.8l29.17 29.17h8.94L94.56 35.86v8.94zM157.94 46.61 121.92 10.6h-8.93l44.95 44.95v-8.94zM94.56 73.97h6.52l-6.52-6.51v6.51zM94.56 65.86l8.11 8.11h8.94L94.56 56.92v8.94zM94.56 55.33l18.64 18.64h8.94L94.56 46.39v8.94z"
    />
    <path
      className="Interfer_svg__cls-4"
      d="m94.56 67.46 6.52 6.51h1.59l-8.11-8.11v1.6zM94.56 56.92l17.05 17.05h1.59L94.56 55.33v1.59zM94.56 46.39l27.58 27.58h1.59L94.56 44.8v1.59zM94.56 35.86l38.11 38.11h1.6l-39.71-39.7v1.59zM94.56 25.33l48.65 48.64h1.59L94.56 23.73v1.6zM94.56 14.79l59.18 59.18h1.59L94.56 13.2v1.59zM157.94 66.08 102.45 10.6h-1.59l57.08 57.07v-1.59zM157.94 55.55 112.99 10.6h-1.6l46.55 46.54v-1.59zM157.94 45.02 123.52 10.6h-1.6l36.02 36.01v-1.59zM157.94 34.48 134.05 10.6h-1.59l25.48 25.48v-1.6zM157.94 23.95 144.58 10.6h-1.59l14.95 14.94v-1.59zM157.94 13.42l-2.82-2.82h-1.6l4.42 4.41v-1.59z"
    />
    <path
      className="Interfer_svg__cls-2"
      d="M93.29 138.41c14.19 9.67 25 5.9 36.87 4.61 26.13-2.82 50.55-11.25 71 12 16.24 18.48 3.6 48.5-9.36 65.3-4.55 5.91-10.35 11.24-17.41 13.94-21 8-37.42-8-56.42-12.8-27.88-7-58.62 3.32-84.27-13.38-16.7-10.85-22.13-30.62-16.97-49.47 2-7.29 6.74-14.48 11.35-20.4 7.77-10 20-18.59 36.79-14.07 8.33 2.24 23.13 10.69 28.42 14.27Z"
    />
    <path
      className="Interfer_svg__cls-1"
      d="M48.63 22.56a17.19 17.19 0 1 1-17.19 17.19 17.2 17.2 0 0 1 17.19-17.19ZM47.29 132.6a9 9 0 1 1-9 9 9 9 0 0 1 9-9ZM149.16 198.84a15.5 15.5 0 0 1 3.73-4.48 7.89 7.89 0 0 1 6-1.86c2.51.32 3.79 2 5.2 3.57 1.59 1.79 3.29 3.48 6.54 3.57s4.82-1.55 6.49-3.28c1.48-1.53 3-3.13 5.95-3.23a6.4 6.4 0 0 1 4.75 2.19 14.88 14.88 0 0 1 2.88 4.37l.22.53 1.05-.43-.22-.52a16.43 16.43 0 0 0-3.12-4.75A7.52 7.52 0 0 0 183 192c-3.34.11-5.06 1.85-6.72 3.57-1.46 1.52-2.87 3-5.65 2.94s-4.33-1.62-5.73-3.19c-1.58-1.78-3.08-3.58-5.9-3.94a9 9 0 0 0-6.81 2.09 16.67 16.67 0 0 0-4 4.85l-.26.5 1 .52ZM173.88 209.14a15.52 15.52 0 0 1 3.74-4.47 7.9 7.9 0 0 1 6-1.87c2.51.32 3.8 2 5.2 3.58 1.59 1.78 3.29 3.47 6.54 3.57s4.83-1.55 6.5-3.29c1.47-1.52 3-3.13 5.94-3.22a6.39 6.39 0 0 1 4.76 2.18 15.24 15.24 0 0 1 2.84 4.38l.21.52 1-.42-.21-.53a16.22 16.22 0 0 0-3.13-4.74 7.56 7.56 0 0 0-5.6-2.53c-3.34.12-5.06 1.86-6.72 3.58-1.46 1.51-2.86 3-5.64 2.94s-4.34-1.63-5.73-3.2c-1.58-1.77-3.08-3.58-5.91-3.94a9 9 0 0 0-6.81 2.1 16.77 16.77 0 0 0-4 4.84l-.27.5 1 .52Z"
    />
    <path
      className="Interfer_svg__cls-4"
      d="M81.7 216.94a3.35 3.35 0 0 1-.55.11 12.17 12.17 0 0 1-1.27.06 11.55 11.55 0 0 1-2.08-.17h-1.32v.86h5.73a4 4 0 0 1 4 4v6.53h5.25v-11.4ZM57.69 187.09h1.52v1.19h-1.52ZM73.35 187.09h1.51v1.19h-1.51ZM75.4 187.09h1.52v1.19H75.4ZM89 187.09h1.52v1.19H89ZM78.13 225h-12a3.23 3.23 0 0 1-1.42-.34v11.66a.87.87 0 0 0 .87.87h11.68a.87.87 0 0 0 .87-.87ZM57.69 177.7h1.52v1.19h-1.52ZM59.75 177.7h1.52v1.19h-1.52ZM73.35 177.7h1.51v1.19h-1.51ZM89 177.7h1.52v1.19H89ZM91.08 177.7h1.52v1.19h-1.52ZM104.68 177.7h1.52v1.19h-1.52ZM106.74 177.7h1.51v1.19h-1.51Z"
    />
    <path
      className="Interfer_svg__cls-4"
      d="m136.73 158.65-.42-.49-7.07 6H46.7l-7.06-6-.42.49 6.93 5.93V228l-6.93 5.92.42.49 7.13-6.09h16.68v-3.59a4 4 0 0 1-1.29-2.94v-4.87h-8.81v-1h8.49a9.13 9.13 0 0 1-.88-.48 7.47 7.47 0 0 1-2.38-2.17h-1.7v-8.61h2.69a7.45 7.45 0 0 1 .6-.65h-3.29v-8.62h7.91a7 7 0 0 1 .46-.65h-8.37v-8.61h15.06v6.57c.22.07.44.15.65.24v-6.81h15.06v8.61H74.91a6.87 6.87 0 0 1 .45.65h12.29V204h-10a4.37 4.37 0 0 1 .2.65h9.82v.39h.65v-.4h1.23a5.1 5.1 0 0 0 .18-.65H88.3v-8.62h2.32c-.07-.21-.13-.43-.18-.65H88.3v-8.61h15.06v8.61h-.88a4.84 4.84 0 0 1-.19.65h1.07v.15h.06a5.13 5.13 0 0 1 .59.12v-.28h15.06V204h-8.61a2.83 2.83 0 0 1 0 .41 1 1 0 0 1 0 .24h8.61v8.61h-13.29v2.65h15.72v1h-15.72v11.4h23.4l7.12 6.1.43-.5-6.93-5.91v-63.42Zm-17.66 26.85H104v-8.61h15.06Zm-62.19-8.61h15.06v8.61H56.88Zm15.71 0h15.06v8.61H72.59Zm15.71 0h15.06v8.61H88.3Zm30.77 9.26v8.61H104v-8.61Zm.33 18.2Z"
    />
    <path
      className="Interfer_svg__cls-4"
      d="M79 211.86v-4.49h6.09a2.26 2.26 0 0 1 .55-.87h-7.52v5.16a1.82 1.82 0 0 0 .33.11 3.79 3.79 0 0 0 .54.09ZM91.49 216v-1h-.65v-5l-.34.09c-.18 0-.36.08-.53.11v4.18H83a2.19 2.19 0 0 1-1.2 1.62ZM73.22 202.33a4.88 4.88 0 1 0-6.28 0Z"
    />
    <path
      className="Interfer_svg__cls-4"
      d="M63 215.05v-3.94a2 2 0 0 1-.7-.84 1.17 1.17 0 0 1-.08-.46 2.45 2.45 0 0 1 .33-1.14 5.29 5.29 0 0 1 .88-1.19v14.32a2.71 2.71 0 0 0 2.71 2.71H79.5v9.43a2.71 2.71 0 0 0 5.42 0V221.8a2.7 2.7 0 0 0-.8-1.91 2.73 2.73 0 0 0-1.91-.79h-7v-4.77a4.85 4.85 0 0 0 2.37 1.23 9.88 9.88 0 0 0 2.32.25c.65 0 1.09-.05 1.14-.05a1.74 1.74 0 0 0-.38-3.45 6.65 6.65 0 0 1-.76 0 6.56 6.56 0 0 1-1.51-.15 1.57 1.57 0 0 1-.75-.33 3.75 3.75 0 0 1-.66-1.71 28.78 28.78 0 0 1-.21-3.79 5.82 5.82 0 0 0-.36-2 2.59 2.59 0 0 0-.83-1.11 2.42 2.42 0 0 0-1.5-.5h-.25a1.43 1.43 0 0 0-.29 0h-8.41a1.65 1.65 0 0 0-.38.05 4.8 4.8 0 0 0-1.75.65 9.64 9.64 0 0 0-2.77 2.46 6.62 6.62 0 0 0-1.45 3.89 4.56 4.56 0 0 0 .3 1.64.14.14 0 0 0 0 .07 5.81 5.81 0 0 0 2.53 2.83 12.17 12.17 0 0 0 1.4.7ZM92.94 196.53a2.91 2.91 0 0 1 .92-.15h6.68a4.88 4.88 0 1 0-7.6.16ZM104.49 209.34a11.71 11.71 0 0 0 2-1 5.81 5.81 0 0 0 2.53-2.83 1.74 1.74 0 0 0 .06-1.08 4.6 4.6 0 0 0 0-.64 6.6 6.6 0 0 0-1.46-3.88 9.48 9.48 0 0 0-2.62-2.41 4.78 4.78 0 0 0-1.76-.65 1.58 1.58 0 0 0-.38 0h-8.42a1.36 1.36 0 0 0-.29 0h-.25a2.47 2.47 0 0 0-1.51.5 2.71 2.71 0 0 0-.83 1.1 6.16 6.16 0 0 0-.36 2 30.72 30.72 0 0 1-.2 3.8 3.63 3.63 0 0 1-.67 1.71 1.55 1.55 0 0 1-.75.33 7 7 0 0 1-1.51.15h-.75a1.74 1.74 0 1 0-.38 3.45s.49 0 1.13 0a10 10 0 0 0 2.33-.25 4.89 4.89 0 0 0 2.36-1.24v26.17a2.68 2.68 0 0 0 5.36 0V215h1v19.56a2.68 2.68 0 1 0 5.36 0Zm.88-6.63a2.54 2.54 0 0 1 .33 1.13 1.23 1.23 0 0 1-.09.47 1.72 1.72 0 0 0-.11.51 3.68 3.68 0 0 1-.77.6l-.24.14v-4a5.62 5.62 0 0 1 .88 1.19Z"
    />
  </svg>
);

export default SvgInterfer;

import * as React from "react";

const SvgAmea = (props) => (
  <svg
    id="Amea_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>{".Amea_svg__cls-1{fill:#5a6e7f}"}</style>
    </defs>
    <path
      className="Amea_svg__cls-1"
      d="M1.82 13.16H.68V.54H13.3v1.14H1.82v11.48z"
    />
    <path
      className="Amea_svg__cls-1"
      transform="rotate(-45 7.284 7.133)"
      d="M6.71-1.39h1.14v17.04H6.71z"
    />
    <path
      className="Amea_svg__cls-1"
      d="M69.32 13.16h-1.14V1.68H56.7V.54h12.62v12.62z"
    />
    <path
      className="Amea_svg__cls-1"
      transform="rotate(-45.01 62.726 7.126)"
      d="M54.2 6.57h17.04v1.14H54.2z"
    />
    <path
      className="Amea_svg__cls-1"
      d="M69.32 69.46H56.7v-1.14h11.48V56.84h1.14v12.62z"
    />
    <path
      className="Amea_svg__cls-1"
      transform="rotate(-45 62.718 62.861)"
      d="M62.15 54.34h1.14v17.04h-1.14z"
    />
    <path
      className="Amea_svg__cls-1"
      d="M13.3 69.46H.68V56.84h1.14v11.48H13.3v1.14z"
    />
    <path
      className="Amea_svg__cls-1"
      transform="rotate(-45.01 7.28 62.87)"
      d="M-1.24 62.3H15.8v1.14H-1.24z"
    />
    <path
      className="Amea_svg__cls-1"
      d="M35.29 52.56A19.69 19.69 0 1 1 55 32.87a19.71 19.71 0 0 1-19.71 19.69Zm0-38.23a18.55 18.55 0 1 0 18.55 18.54 18.56 18.56 0 0 0-18.55-18.54Z"
    />
    <path
      className="Amea_svg__cls-1"
      d="M41.05 59.12H30a.57.57 0 0 1-.57-.57v-7.31a.56.56 0 0 1 .57-.57.57.57 0 0 1 .57.57V58h9.93v-6.21a.57.57 0 0 1 .12-1.12h.45a.57.57 0 0 1 .57.57v7.31a.58.58 0 0 1-.59.57Z"
    />
    <path
      d="M32.22 43.85a8.34 8.34 0 0 1-4.09-15.61.59.59 0 0 1 .78.22.57.57 0 0 1-.22.77 7.2 7.2 0 1 0 9.57 10.19.57.57 0 0 1 .79-.17.58.58 0 0 1 .17.79 8.33 8.33 0 0 1-7 3.81Z"
      style={{
        fill: "#97d5c9",
      }}
    />
    <circle className="Amea_svg__cls-1" cx={32.6} cy={22.13} r={3.31} />
    <path
      className="Amea_svg__cls-1"
      d="M44.88 43.85a.58.58 0 0 1-.52-.34l-3.48-7.83-8.15 2a.56.56 0 0 1-.7-.55v-8.85a.57.57 0 0 1 .57-.57.58.58 0 0 1 .57.57v8.12l7.9-1.94a.59.59 0 0 1 .66.32l3.67 8.27a.57.57 0 0 1-.29.75.59.59 0 0 1-.23.05Z"
    />
  </svg>
);

export default SvgAmea;

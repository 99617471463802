import "./../css/Privacy.css";

const Privacy = () => (
  <div className={"privacy__overlay"}>
    <div>
      <h1>Προσωπικά Δεδομένα</h1>
      <p>
        Η ανώνυμη εταιρεία με την επωνυμία DOTSOFT ΑE ενημερώνει το πρόσωπο το
        οποίο αφορούν τα εκάστοτε προσωπικά δεδομένα (εφεξής το Υποκείμενο),
        επισκέπτη/χρήστη του παρόντος διαδικτυακού τόπου (εφεξής Διαδικτυακός
        Τόπος), ότι η επεξεργασία των προσωπικών δεδομένων διέπεται από τους
        όρους που ακολουθούν, από τις σχετικές διατάξεις της εκάστοτε ισχύουσας
        ελληνικής και ευρωπαϊκής νομοθεσίας περί προσωπικών δεδομένων καθώς και
        από τις σχετικές αποφάσεις, οδηγίες και κανονιστικές πράξεις της Αρχής
        Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
      </p>

      <h1>ΥΠΕΥΘΥΝΟΣ ΕΠΕΞΕΡΓΑΣΙΑΣ</h1>
      <p>
        Υπεύθυνος επεξεργασίας είναι η ανώνυμη εταιρεία με την επωνυμία DOTSOFT
        AE, Κουντουριώτου 3, 546 25, Θεσσαλονίκη, Τηλ: 2310500181.
      </p>

      <h1>ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΠΟΥ ΣΥΛΛΕΓΕΙ ΚΑΙ ΕΠΕΞΕΡΓΑΖΕΤΑΙ Η DOTSOFT</h1>
      <p>
        Η DOTSOFT διασφαλίζει τη θεμιτή και νόμιμη συλλογή και επεξεργασία
        δεδομένων προσωπικού χαρακτήρα μέσω του Διαδικτυακού Τόπου της. Τα
        προσωπικά δεδομένα είναι πληροφορίες που ταυτοποιούν άμεσα ή έμμεσα το
        Υποκείμενο, όπως το όνομα, η διεύθυνση ηλεκτρονικού ταχυδρομείου ή η
        ταχυδρομική διεύθυνση. Η DOTSOFT τηρεί αρχείο και επεξεργάζεται
        προσωπικά δεδομένα που της παρέχουν τα ίδια τα Υποκείμενα. Η επεξεργασία
        αυτών των προσωπικών δεδομένων γίνεται με τη συγκατάθεσή των Υποκειμένων
        και μόνο στον βαθμό που είναι απαραίτητο ανάλογα με τον σκοπό της
        επεξεργασίας και τον χρόνο που απαιτείται σε κάθε περίπτωση. Κάθε φορά
        συλλέγουμε τα προσωπικά δεδομένα που είναι απολύτως απαραίτητα για να
        υλοποιηθεί η ενέργεια ή η υπηρεσία που έχει ζητήσει το Υποκείμενο (βλ.
        κατωτέρω «Σκοποί Επεξεργασίας»).
      </p>

      <h1>ΣΚΟΠΟΙ ΕΠΕΞΕΡΓΑΣΙΑΣ</h1>
      <p>
        Η DOTSOFT ή/και τρίτοι που εκτελούν την επεξεργασία κατ’ εντολή και για
        λογαριασμό της:
      </p>
      <p>
        Επεξεργάζονται προσωπικά δεδομένα των Υποκειμένων με σκοπό να
        επικοινωνήσουν μαζί τους εφόσον τα Υποκείμενα έχουν συμπληρώσει
        ηλεκτρονικά τις φόρμες που είναι διαθέσιμες στον Διαδικτυακό Τόπο (όπως
        φόρμα επικοινωνίας κ.λπ.) ή έχουν ζητήσει να λαμβάνουν ενημερώσεις μέσω
        newsletter κ.λπ., ή μέσω ηλεκτρονικών μηνυμάτων, αφού έχουν εγγραφεί
        στον Διαδικτυακό Τόπο.
      </p>
      <p>
        Επεξεργάζονται προσωπικά δεδομένα των Υποκειμένων με σκοπό την
        εξυπηρέτησή τους και τη διεκπεραίωση των αιτημάτων που έχουν
        ηλεκτρονικώς υποβάλλει τα Υποκείμενα μέσω του Διαδικτυακού Τόπου.
      </p>
      <p>
        Επεξεργάζονται προσωπικά δεδομένα των Υποκειμένων με σκοπό τη διαχείριση
        στελέχωσης και ανθρώπινου δυναμικού της DOTSOFT.
      </p>
      <p>
        Ενδέχεται να επεξεργαστούν προσωπικά δεδομένα των Υποκειμένων με σκοπό:
      </p>
      <ul>
        <li>Τη διενέργεια ελέγχων που προβλέπονται από νομοθετήματα.</li>

        <li>Την πρόληψη, αποτροπή και καταστολή παρανόμων πράξεων.</li>

        <li>Την αναβάθμιση των παρεχόμενων υπηρεσιών.</li>

        <li>
          Εφόσον συντρέχουν οι εκάστοτε νόμιμες προϋποθέσεις, την προώθηση των
          υπηρεσιών και προϊόντων της DOTSOFT.
        </li>
      </ul>
      <p>
        Η DOTSOFT συλλέγει και επεξεργάζεται προσωπικά δεδομένα αποκλειστικά και
        μόνο για τους προαναφερόμενους σκοπούς και μόνο στο απολύτως αναγκαίο
        μέτρο για την αποτελεσματική εξυπηρέτηση των σκοπών αυτών. Τα δεδομένα
        αυτά είναι κάθε φορά συναφή, πρόσφορα και όχι περισσότερα από όσα
        απαιτούνται εν όψει των ανωτέρω σκοπών, είναι δε ακριβή και, εφόσον
        χρειάζεται, υποβάλλονται σε ενημέρωση. Επιπλέον αυτά τα δεδομένα
        διατηρούνται μόνο για το χρονικό διάστημα που απαιτείται για να
        πραγματοποιηθούν οι σκοποί της συλλογής και επεξεργασίας τους.
        Διαγράφονται με ασφαλή τρόπο μετά την ολοκλήρωση της επεξεργασίας, εκτός
        εάν η κείμενη νομοθεσία προβλέπει τη συνέχιση της τήρησής τους.
      </p>

      <h1>ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΑΝΗΛΙΚΩΝ</h1>
      <p>
        Η DOTSOFT αντιλαμβάνεται τη σημασία της προστασίας των προσωπικών
        δεδομένων των ανηλίκων. Ο Διαδικτυακός Τόπος ούτε απευθύνεται ούτε είναι
        σχεδιασμένος σκοπίμως για να απευθύνεται σε ανηλίκους. Η πρόθεση της
        DOTSOFT είναι να μη συλλέγει ούτε να τηρεί εν γνώσει της προσωπικά
        δεδομένα ανηλίκων που τυχόν έχουν πρόσβαση στον Διαδικτυακό Τόπο της.
      </p>

      <p>
        Ωστόσο, δεδομένου ότι αυτό δεν είναι εφικτό να διασφαλιστεί/
        επιβεβαιωθεί από την DOTSOFT, τυχόν ανήλικοι χρήστες του Διαδικτυακού
        Τόπου που διαβιβάζουν, μέσω αυτού, προσωπικά τους δεδομένα στην DOTSOFT,
        υποχρεούνται και αναμένεται να έχουν λάβει τη συγκατάθεση των ασκούντων
        τη γονική μέριμνα αυτών ή των τυχόν επιτρόπων τους. Συνίσταται δε στους
        ενήλικες να ασκούν τη δέουσα εποπτεία των υπ’ ευθύνη τους ανηλίκων κατά
        την περιήγηση των τελευταίων στο διαδίκτυο και ιδίως στον παρόντα
        Διαδικτυακό Τόπο.
      </p>

      <h1>ΑΣΦΑΛΕΙΑ ΤΩΝ ΔΕΔΟΜΕΝΩΝ</h1>

      <p>
        Η διαδικασία επεξεργασίας προσωπικών δεδομένων από την DOTSOFT
        διεξάγεται με τρόπο που διασφαλίζει το απόρρητο αυτής. Η DOTSOFT
        λαμβάνει όλα τα κατάλληλα οργανωτικά και τεχνικά μέτρα για την ασφάλεια
        των δεδομένων και την προστασία τους από τυχαία ή αθέμιτη καταστροφή,
        τυχαία απώλεια, αλλοίωση, απαγορευμένη διάδοση ή πρόσβαση και κάθε άλλη
        μορφή αθέμιτης επεξεργασίας.
      </p>

      <p>
        Αν και καταβάλλεται κάθε προσπάθεια για την προστασία των προσωπικών
        δεδομένων, η DOTSOFT δεν μπορεί να εγγυηθεί την ασφάλεια των δεδομένων
        που μεταδίδονται στον Διαδικτυακό της Τόπο, καθώς η μετάδοση πληροφοριών
        μέσω του διαδικτύου δεν είναι απόλυτα ασφαλής.
      </p>

      <h1>ΑΠΟΔΕΚΤΕΣ ΤΩΝ ΔΕΔΟΜΕΝΩΝ</h1>
      <p>
        Αποδέκτες των προσωπικών δεδομένων είναι η ίδια η DOTSOFT, και εφόσον
        συντρέχουν οι εκάστοτε νόμιμες προϋποθέσεις, οι συνεργαζόμενες με αυτήν
        εταιρείες με έδρα στην Ελλάδα ή τις άλλες χώρες της Ευρωπαϊκής Ένωσης
        στο πλαίσιο των δραστηριοτήτων τους. Εκτελούντες την επεξεργασία για
        λογαριασμό της DOTSOFT
      </p>

      <p>
        Η DOTSOFT ενδέχεται να χρησιμοποιεί για την επεξεργασία των προσωπικών
        δεδομένων συνεργαζόμενες εταιρείες που ενεργούν στο όνομα και για
        λογαριασμό της για τους ανωτέρω αναφερόμενους σκοπούς επεξεργασίας.
        Λοιπά τρίτα μέρη
      </p>

      <p>
        Η DOTSOFT ενδέχεται επίσης να κοινοποιήσει προσωπικά δεδομένα των
        Υποκειμένων εφόσον απαιτείται από τον νόμο, δικαστική ή κανονιστική
        απόφαση ή όταν οφείλει να προστατεύσει τον Διαδικτυακό της Τόπο.
        Συγκεκριμένα μπορεί:
      </p>

      <ul>
        <li>
          Να τα διαβιβάσει προς κρατικές υπηρεσίες, δικαστικές/εισαγγελικές
          αρχές για να συμμορφωθεί με την ισχύουσα νομοθεσία ή για να
          ανταποκριθεί σε υποχρεωτική νομική διαδικασία.
        </li>

        <li>
          Να τα χρησιμοποιήσει για την προστασία και την αποφυγή εξαπάτησης των
          χρηστών του Διαδικτυακού Τόπου ή για να αποτρέψει την απώλεια ζωής ή
          την πιθανότητα σοβαρού τραυματισμού.
        </li>
        <li>
          Να τα χρησιμοποιήσει για τη λειτουργία και συντήρηση της ασφάλειας των
          συστημάτων της, μεταξύ άλλων για την πρόληψη ή τη διακοπή μιας
          επίθεσης στα συστήματα ή τα δίκτυά της.
        </li>
      </ul>
      <h1>COOKIES</h1>
      <p>
        Η DOTSOFT για να εξασφαλίσει τη σωστή λειτουργία του Διαδικτυακού της
        Τόπου, αποστέλλει και αποθηκεύει cookies, δηλαδή μικρά αρχεία δεδομένων,
        στον υπολογιστή των Υποκειμένων. Ενημερωθείτε για την Πολιτική cookies
        της DOTSOFT.
      </p>

      <h1>ΣΥΝΔΕΣΗ ΜΕ ΔΙΑΔΙΚΤΥΑΚΟΥΣ ΤΟΠΟΥΣ ΤΡΙΤΩΝ</h1>

      <p>
        Οποιαδήποτε τυχόν διασύνδεση του Διαδικτυακού Τόπου μέσω ειδικών
        υπερσυνδέσμων (links, hyperlinks, banners) με οποιονδήποτε άλλο
        διαδικτυακό τόπο που ανήκει σε τρίτους δεν συνεπάγεται ότι η DOTSOFT
        αναλαμβάνει οποιαδήποτε ευθύνη για την πολιτική που ακολουθούν αυτοί οι
        διαδικτυακοί τόποι σχετικά με την προστασία και διαχείριση των
        προσωπικών δεδομένων.
      </p>

      <p>
        Τα Υποκείμενα θα πρέπει να φροντίζουν τα ίδια να ενημερώνονται για την
        προστασία και διαχείριση των δεδομένων τους από τους ανωτέρω
        διαδικτυακούς τόπους.
      </p>

      <h1>ΔΙΚΑΙΩΜΑΤΑ ΠΡΟΣΒΑΣΗΣ ΚΑΙ ΑΝΤΙΡΡΗΣΗΣ</h1>

      <p>
        Το Υποκείμενο δικαιούται να γνωρίζει εάν δεδομένα προσωπικού του
        χαρακτήρα αποτελούν ή αποτέλεσαν αντικείμενο επεξεργασίας (δικαίωμα
        πρόσβασης). Για την άσκηση αυτού του δικαιώματός του, το Υποκείμενο
        μπορεί να απευθύνεται: Στη DOTSOFT AE – Εγγράφως: Κουντουριώτου 3, 546
        25 Θεσσαλονίκη – Τηλεφωνικά: 2310500181
      </p>

      <p>
        Επίσης το Υποκείμενο δικαιούται οποιαδήποτε στιγμή να προβάλλει εγγράφως
        αντιρρήσεις για την επεξεργασία των προσωπικών του δεδομένων (δικαίωμα
        αντίρρησης, άρθρο 13 Ν.2472/1997) και να ζητάει συγκεκριμένη ενέργεια,
        όπως διόρθωση, προσωρινή μη χρησιμοποίηση, δέσμευση, μη διαβίβαση,
        διαγραφή. Οι αντιρρήσεις θα απευθύνονται εγγράφως στην παραπάνω
        διελυθυνση.
      </p>

      <p>
        Κατά την άσκηση αυτών των δικαιωμάτων και για την ικανοποίησή τους,
        ενδέχεται η DOTSOFT να ζητήσει από τα Υποκείμενα να επαληθεύσουν την
        ταυτότητά τους προκειμένου να μπορέσει να ικανοποιήσει το αίτημά τους.
      </p>

      <h1>ΕΠΙΚΟΙΝΩΝΙΑ ΜΕ ΤΗΝ ΑΡΧΗ ΠΡΟΣΤΑΣΙΑΣ ΔΕΔΟΜΕΝΩΝ ΠΡΟΣΩΠΙΚΟΥ ΧΑΡΑΚΤΗΡΑ</h1>
      <p>
        Στην περίπτωση που τα Υποκείμενα θεωρούν ότι θίγεται κατά οποιονδήποτε
        τρόπο η προστασία των προσωπικών τους δεδομένων, μπορούν να προσφύγουν
        στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα:
      </p>

      <ul>
        <li>Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα</li>
        <li>Κηφισίας 1-3, 11523 Αθήνα</li>
        <li>Τηλ: 2106475628</li>
        <li>Email: contact@dpa.gr</li>
      </ul>

      <h1>ΤΡΟΠΟΠΟΙΗΣΗ ΤΩΝ ΟΡΩΝ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h1>

      <p>
        Επειδή η παρούσα ενημέρωση και οι εμπεριεχόμενοι σε αυτή όροι προστασίας
        των δεδομένων προσωπικού χαρακτήρα ενδέχεται να τροποποιούνται, τα
        Υποκείμενα πρέπει να ενημερώνονται τακτικά για το περιεχόμενο της
        παρούσας και να ελέγχουν για τυχόν αλλαγές.
      </p>
    </div>
  </div>
);

export default Privacy;

import * as React from "react";

const SvgParkingManageCenter = (props) => (
  <svg
    id="ParkingManageCenter_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>
        {
          ".ParkingManageCenter_svg__cls-1{fill:#5a6e7f}.ParkingManageCenter_svg__cls-2{fill:#97d5c9}"
        }
      </style>
    </defs>
    <path
      className="ParkingManageCenter_svg__cls-1"
      d="M45.13 69.21H14.44A12.14 12.14 0 0 1 2.32 57.09V26.4a12.14 12.14 0 0 1 12.12-12.12h30.69A12.14 12.14 0 0 1 57.25 26.4v30.69a12.14 12.14 0 0 1-12.12 12.12ZM14.44 15.5a10.91 10.91 0 0 0-10.9 10.9v30.69A10.91 10.91 0 0 0 14.44 68h30.69A10.91 10.91 0 0 0 56 57.09V26.4a10.91 10.91 0 0 0-10.9-10.9Z"
    />
    <path
      className="ParkingManageCenter_svg__cls-2"
      d="M17.86 26.65a59.73 59.73 0 0 1 9.72-.65c4.43 0 7.58.85 9.71 2.55a9.3 9.3 0 0 1 3.39 7.35 10.21 10.21 0 0 1-2.92 7.55c-2.45 2.31-6.08 3.34-10.33 3.34a17.23 17.23 0 0 1-2.43-.15V58h-7.14ZM25 41.08a11.14 11.14 0 0 0 2.41.19c3.81 0 6.17-1.94 6.17-5.19 0-2.92-2-4.67-5.61-4.67a12.22 12.22 0 0 0-3 .29Z"
    />
    <circle
      cx={53.2}
      cy={19.92}
      r={13.35}
      style={{
        fill: "#fff",
      }}
    />
    <path
      className="ParkingManageCenter_svg__cls-1"
      d="M53.2 33.88a14 14 0 1 1 14-14 14 14 0 0 1-14 14Zm0-26.7a12.75 12.75 0 1 0 12.74 12.74A12.76 12.76 0 0 0 53.2 7.18Z"
    />
    <circle
      className="ParkingManageCenter_svg__cls-2"
      cx={53.2}
      cy={19.92}
      r={2.05}
    />
    <path
      className="ParkingManageCenter_svg__cls-2"
      d="M52.59 10.19h1.21v9.4h-1.21z"
    />
    <rect
      className="ParkingManageCenter_svg__cls-1"
      x={49.32}
      y={0.79}
      width={7.75}
      height={3.3}
      rx={1.59}
    />
    <path
      className="ParkingManageCenter_svg__cls-1"
      d="M51.96 4.09h2.48v2.48h-2.48z"
    />
    <rect
      className="ParkingManageCenter_svg__cls-1"
      x={62.03}
      y={7.04}
      width={6.14}
      height={2.61}
      rx={1.26}
      transform="rotate(45 65.103 8.346)"
    />
    <path
      className="ParkingManageCenter_svg__cls-1"
      transform="rotate(45 63.482 9.965)"
      d="M62.5 8.98h1.97v1.97H62.5z"
    />
  </svg>
);

export default SvgParkingManageCenter;

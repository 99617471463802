import * as React from "react";

const SvgSensors = (props) => (
  <svg
    id="Sensors_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>{".Sensors_svg__cls-1{fill:#5a6e7f}"}</style>
    </defs>
    <path
      className="Sensors_svg__cls-1"
      d="M55.66 69.68H5.46A.54.54 0 0 1 5 69l5.28-16.78a1.82 1.82 0 0 1 3.16-.6 22.2 22.2 0 0 0 34.72 0 1.81 1.81 0 0 1 1.72-.62 1.84 1.84 0 0 1 1.4 1.29L56.17 69a.53.53 0 0 1-.08.47.55.55 0 0 1-.43.21ZM6.18 68.62H55l-4.69-16a.77.77 0 0 0-1.32-.27 23.24 23.24 0 0 1-36.38 0 .72.72 0 0 0-.72-.26.75.75 0 0 0-.6.52Z"
    />
    <path
      className="Sensors_svg__cls-1"
      d="M30.77 55A20.21 20.21 0 1 1 51 34.75 20.23 20.23 0 0 1 30.77 55Zm0-39.34a19.14 19.14 0 1 0 19.14 19.09 19.15 19.15 0 0 0-19.14-19.13Z"
    />
    <path
      className="Sensors_svg__cls-1"
      d="M30.77 42.9a8.15 8.15 0 1 1 8.14-8.15 8.15 8.15 0 0 1-8.14 8.15Zm0-15.23a7.09 7.09 0 1 0 7.08 7.08 7.1 7.1 0 0 0-7.08-7.08Z"
    />
    <circle
      cx={32.51}
      cy={33.01}
      r={2.8}
      style={{
        fill: "#97d5c9",
      }}
    />
    <path
      className="Sensors_svg__cls-1"
      d="M54.15 30.28a.53.53 0 0 1-.52-.42A23.51 23.51 0 0 0 34.69 11.7a.53.53 0 0 1-.43-.61.52.52 0 0 1 .61-.43 24.56 24.56 0 0 1 19.8 19 .53.53 0 0 1-.41.63Z"
    />
    <path
      className="Sensors_svg__cls-1"
      d="M59.46 30.28a.53.53 0 0 1-.52-.44A28.45 28.45 0 0 0 34.71 6.43a.53.53 0 0 1 .15-1A29.65 29.65 0 0 1 60 29.66a.52.52 0 0 1-.43.61Z"
    />
    <path
      className="Sensors_svg__cls-1"
      d="M64.54 30.28a.53.53 0 0 1-.52-.46 33.61 33.61 0 0 0-29.3-28.44.52.52 0 0 1-.46-.59.52.52 0 0 1 .58-.46 34.65 34.65 0 0 1 30.23 29.34.53.53 0 0 1-.45.6Z"
    />
  </svg>
);

export default SvgSensors;

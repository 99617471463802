import * as React from "react";

const SvgPolice2 = (props) => (
  <svg
    id="Police_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 700 700"
    style={{
      enableBackground: "new 0 0 700 700",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".Police_svg__st0{fill:#5a6e7f}"}</style>
    <path
      className="Police_svg__st0"
      d="M564.5 340.8h-31.4L513 278c-10.6-32.5-40.3-53.8-73.9-53.8H261c-34.2 0-63.8 21.8-73.9 53.8l-20.2 62.7h-31.4c-13.4 0-24.6 11.2-24.6 24.6v14c0 13.4 11.2 24.6 24.6 24.6h9c-5 22.4-7.8 45.9-7.8 68.9v118.7c0 13.4 11.2 24.6 24.6 24.6h40.9c13.4 0 24.6-11.2 24.6-24.6v-38.1h246.4v38.1c0 13.4 11.2 24.6 24.6 24.6h40.9c13.4 0 24.6-11.2 24.6-24.6V472.9c0-23.5-2.8-46.5-7.8-68.9h9c13.4 0 24.6-11.2 24.6-24.6v-14c0-13.4-11.2-24.6-24.6-24.6zM210 366l23.5-73.4c3.9-11.8 15.1-20.2 27.4-20.2H439c12.3 0 23.5 7.8 27.4 20.2L490 366c1.1 3.4-1.7 6.7-5 6.7H215.1c-4 .5-6.2-3.4-5.1-6.7zm51.5 120.4h-49.8c-8.4 0-15.1-6.7-15.1-15.1v-23.5c0-9.5 8.4-16.2 17.4-15.1l46.5 7.3c6.2 1.1 11.2 5.6 12.3 11.8l3.4 15.7c2.1 9.9-5.2 18.9-14.7 18.9zm241.4-15.2c0 8.4-6.7 15.1-15.1 15.1H438c-9.5 0-16.8-9-14.6-18.5l3.4-15.7c1.1-6.2 6.2-10.6 12.3-11.8l46.5-7.3c9-1.7 17.4 5.6 17.4 15.1l-.1 23.1zM407.7 181.2c0 6.7 5.6 12.3 12.3 12.3h14.6c6.7 0 12.3-5.6 12.3-12.3s-5.6-12.3-12.3-12.3H420c-6.7-.1-12.3 5.5-12.3 12.3zM399.8 144.2c3.4 0 6.7-1.1 9-3.9l10.6-10.6c2.2-2.2 3.9-5.6 3.9-9s-1.1-6.7-3.9-9c-5-5-12.9-5-17.4 0l-10.6 10.6c-2.2 2.2-3.9 5.6-3.9 9 0 3.4 1.1 6.7 3.9 9 1.7 2.2 5.1 3.9 8.4 3.9zM350 123.5c6.7 0 12.3-5.6 12.3-12.3V96c0-6.7-5.6-12.3-12.3-12.3s-12.3 5.6-12.3 12.3v14.6c0 7.3 5.6 12.9 12.3 12.9zM291.8 140.3c2.2 2.2 5.6 3.9 9 3.9s6.7-1.1 9-3.9c2.2-2.2 3.9-5.6 3.9-9 0-3.4-1.1-6.7-3.9-9L299 111.7c-2.2-2.2-5.6-3.9-9-3.9s-6.7 1.1-9 3.9c-2.2 2.2-3.9 5.6-3.9 9s1.1 6.7 3.9 9l10.8 10.6zM264.9 193.5h14.6c6.7 0 12.3-5.6 12.3-12.3s-5.6-12.3-12.3-12.3h-14.6c-6.7 0-12.3 5.6-12.3 12.3s5.6 12.3 12.3 12.3zM387 180c0-20.2-16.2-37-37-37-20.2 0-37 16.2-37 37v24.1h73.4V180h.6z"
    />
  </svg>
);

export default SvgPolice2;

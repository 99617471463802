import * as React from "react";

const SvgOnOff = (props) => (
  <svg
    id="OnOff_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>{".OnOff_svg__cls-1{fill:#97d5c9}"}</style>
    </defs>
    <ellipse
      className="OnOff_svg__cls-1"
      cx={18.23}
      cy={35.02}
      rx={16.65}
      ry={17.29}
    />
    <path
      d="M52.94 52.85H17.06a16 16 0 0 1-16-16v-3.7a16 16 0 0 1 16-16h35.88a16 16 0 0 1 16 16v3.7a16 16 0 0 1-16 16ZM17.06 18.18a15 15 0 0 0-15 15v3.7a15 15 0 0 0 15 15h35.88a15 15 0 0 0 15-15v-3.7a15 15 0 0 0-15-15Z"
      style={{
        fill: "#5a6e7f",
      }}
    />
    <path
      className="OnOff_svg__cls-1"
      d="M38.32 35.9a5.94 5.94 0 0 1 .58-2.66 4.31 4.31 0 0 1 1.64-1.81 4.69 4.69 0 0 1 2.46-.64 4.38 4.38 0 0 1 3.29 1.31 5.12 5.12 0 0 1 1.37 3.46v.53a6 6 0 0 1-.57 2.65 4.21 4.21 0 0 1-1.63 1.81 4.71 4.71 0 0 1-2.45.64 4.37 4.37 0 0 1-3.4-1.41A5.43 5.43 0 0 1 38.32 36Zm2.25.19a4.05 4.05 0 0 0 .64 2.43 2.09 2.09 0 0 0 1.78.87 2.05 2.05 0 0 0 1.77-.89 4.4 4.4 0 0 0 .64-2.6 4 4 0 0 0-.65-2.41A2.11 2.11 0 0 0 43 32.6a2.06 2.06 0 0 0-1.75.88 4.36 4.36 0 0 0-.68 2.61ZM50.34 41v-8.35h-1.53V31h1.53v-.92a3.41 3.41 0 0 1 .92-2.57 3.54 3.54 0 0 1 2.6-.91 4.87 4.87 0 0 1 1.26.17l-.06 1.76a4.34 4.34 0 0 0-.86-.08c-1.08 0-1.61.55-1.61 1.66V31h2v1.67h-2V41ZM57.06 41v-8.35h-1.53V31h1.53v-.92a3.42 3.42 0 0 1 .94-2.59 3.53 3.53 0 0 1 2.59-.91 4.76 4.76 0 0 1 1.26.17l-.05 1.76a4.42 4.42 0 0 0-.86-.08 1.45 1.45 0 0 0-1.62 1.66V31h2v1.67h-2V41Z"
    />
  </svg>
);

export default SvgOnOff;

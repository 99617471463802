import "../css/description.css";
import { useTranslation } from "react-i18next";
import {
  Section_1_svgIcon,
  Section_2_svgIcon,
  Section_3_svgIcon,
  Section_4_svgIcon,
  Section_5_svgIcon,
  setction_1_array,
  setction_1_arrayTitle,
  setction_1_title,
  setction_2_array,
  setction_2_arrayTitle,
  setction_2_title,
  setction_3_array,
  setction_3_arrayTitle,
  setction_3_title,
  setction_4_array,
  setction_4_arrayTitle,
  setction_4_title,
  setction_5_arrayTitle,
  setction_5_title,
  subsystemTitle,
} from "../data/staticData";

const Description = () => {
  const { t } = useTranslation();
  return (
    <div className={"left-right-flex  functionality-content  maxWidth__class"}>
      <h2 style={{ marginTop: "40px" }}>{subsystemTitle}</h2>
      <div className="functionality-description-1">
        <div className="functionality-description-1__column-1">
          {Section_1_svgIcon && <Section_1_svgIcon />}
        </div>
        <div className="functionality-description-1__column-2">
          <h2>{t(setction_1_title)}</h2>
          <p>
            {t(setction_1_arrayTitle)}
            {setction_1_array.map((item, index) => (
              <li key={index}>{t(item)}</li>
            ))}
          </p>
        </div>
      </div>
      <div className="functionality-description-2">
        <div className="functionality-description-2__column-1">
          <h2>{t(setction_2_title)}</h2>
          <p>
            {t(setction_2_arrayTitle)}
            {setction_2_array.map((item, index) => (
              <li key={index}>{t(item)}</li>
            ))}
          </p>
        </div>
        <div className="functionality-description-2__column-2">
          {Section_2_svgIcon && <Section_2_svgIcon />}
        </div>
      </div>
      <div
        className="functionality-description-3"
        id="functionality-description-3"
      >
        <div className="functionality-description-3__column-1">
          {Section_3_svgIcon && <Section_3_svgIcon />}
        </div>
        <div className="functionality-description-3__column-2">
          <h2>{t(setction_3_title)}</h2>
          <p>
            {t(setction_3_arrayTitle)}
            {setction_3_array.map((item, index) => (
              <li key={index}>{t(item)}</li>
            ))}
          </p>
        </div>
      </div>

      <div className="functionality-description-2">
        <div className="functionality-description-2__column-1">
          <h2>{t(setction_4_title)}</h2>
          <p>
            {t(setction_4_arrayTitle)}
            {setction_4_array.map((item, index) => (
              <li key={index}>{t(item)}</li>
            ))}
          </p>
        </div>
        <div className="functionality-description-2__column-2">
          {Section_4_svgIcon && <Section_4_svgIcon />}
        </div>
      </div>
      <div
        className="functionality-description-3"
        id="functionality-description-3"
      >
        <div className="functionality-description-3__column-1">
          {Section_5_svgIcon && <Section_5_svgIcon />}
        </div>
        <div className="functionality-description-3__column-2">
          <h2>{t(setction_5_title)}</h2>
          <p>{t(setction_5_arrayTitle)}</p>
        </div>
      </div>
    </div>
  );
};
export default Description;

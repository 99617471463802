import SvgSensors from "../icons/functions/Sensors";
import SvgOnOff from "../icons/functions/OnOff";
import SvgParking from "../icons/functions/Parking";
import SvgEBills from "../icons/functions/EBills";
import SvgIndexes from "../icons/functions/Indexes";
import SvgMobileApp from "../icons/functions/MobileApp";
import SvgAmea from "../icons/functions/Amea";
import SvgPolice from "../icons/functions/Police";
import SvgParkingManageCenter from "../icons/functions/ParkingManageCenter";
import SvgRf from "../icons/functions/Rf";
import SvgAdditionalFunctions from "../icons/functions/AdditionFunctions";
import SvgConnections from "../icons/functions/Connections";

import SvgCollection from "../icons/subsystems/Collection";
import SvgInformation from "../icons/subsystems/Information";
import SvgInterfere from "../icons/subsystems/Interfer";
import SvgManaging from "../icons/subsystems/Mananging";
import SvgRegister from "../icons/subsystems/Register";

import SvgAmea2 from "../icons/recommended/Amea2";
import SvgCeo from "../icons/recommended/Ceo";
import SvgCitizenVisitors from "../icons/recommended/CitizenVisitors";
import SvgMunicipality from "../icons/recommended/Municipality";
import SvgPolice2 from "../icons/recommended/Police2";

export const appNameTitle = (
  <>
    <span style={{ color: "#97D5C9" }}>easy</span>
    <span style={{ color: "#5A6E7F" }}>parking</span>
  </>
);
export const basicFunc = (
  <>Βασικές λειτουργίες της πλατφόρμας του {appNameTitle}</>
);

export const basicFuncArray = [
  {
    title: "Εποπτεία με χρήση αισθητήρων ή/ και καμερών",
    svgIcon: SvgSensors,
  },
  {
    title: "Λειτουργία χωρίς την χρήση αισθητήρων ή/ και καμερών",
    svgIcon: SvgOnOff,
  },
  {
    title: "Κεντρική διαχείριση θέσεων στάθμευσης",
    svgIcon: SvgParking,
  },
  {
    title: "Ηλεκτρονικές Πληρωμές μέσω mobile app/ web",
    svgIcon: SvgEBills,
  },
  {
    title: "Δείκτες απόδοσης συστήματος",
    svgIcon: SvgIndexes,
  },
  {
    title: "Mobile app για την πληροφόρηση οδηγών",
    svgIcon: SvgMobileApp,
  },
  {
    title: "Εποπτεία θέσεων για ΑμΕΑ και φορτοεκφόρτωσης",
    svgIcon: SvgAmea,
  },
  {
    title: "Εφαρμογή για τη δημοτική αστυνομία",
    svgIcon: SvgPolice,
  },
  {
    title: "Διαχείριση παρόδιας στάθμευσης ή/και κλειστών χώρων",
    svgIcon: SvgParkingManageCenter,
  },
  {
    title: "Λειτουργία αισθητήρων σε NBIoT, RF, LoraWan",
    svgIcon: SvgRf,
  },

  {
    box: "large",
    svgIcon: SvgAdditionalFunctions,
    title: "Επιπλέον Λειτουργίες",
    list: [
      "Τελευταία θέση στάθμευσης",
      "Οι επόμενες ελεύθερες θέσεις",
      "Αποστολή ελεύθερης θέσης σε φίλο",
      "Θέσεις στάθμευσης ¨κοντά μου¨",
    ],
  },
  {
    box: "large",
    svgIcon: SvgConnections,
    title: "Διασυνδέσεις",
    list: [
      "Πλατφόρμα Εξυπνης Πόλης",
      // "Ηλεκτρονικές Πινακίδες (Guidance Systems)",
      "Εφαρμογές Οδηγών Πόλεων",
      "Διασύνδεση με Λογισμικό οικονομικής υπηρεσίας",
    ],
  },
];

export const subsystemTitle = <>Βασικά υποσυστήματα του {appNameTitle}</>;
export const setction_1_title =
  "Υποσύστημα Καταγραφής, Ανάλυσης και Πρόβλεψης συμπεριφοράς στάθμευσης στην περιοχή υψηλής συμφόρησης για την παρόδια στάθμευση και την κίνηση με χρήση καμερών";
export const setction_1_arrayTitle = "Περιλαμβάνει";
export const setction_1_array = [
  "Κάμερες τύπου Bullet ή/και τύπου Dome με δυνατότητα μέτρησης οχημάτων, ανίχνευση αντικειμένων εν κινήσει, ανίχνευσης και επεξεργασίας ελεύθερης ή μη θέσης στάθμευσης σε παρόδια στάθμευση ή σε ανοικτό χώρο. Οι κάμερες συνοδεύονται από αλγόριθμο θόλωσης και anonymization. Με τον τρόπο αυτό επιτυγχάνεται η ανίχνευση διερχόμενων οχήματα και μετρήσεις βασικών μεγεθών κυκλοφορίας (κυκλοφοριακό φόρτος (μοτοσυκλέτες-ποδήλατα, επιβατικά, φορτηγά/λεωφορεία, μεγάλα φορτηγά /ώρα), όπως και πεζούς σε ιδιαίτερες περιπτώσεις - μέση ταχύτητα διαδρομής (χλμ/ώρα), χωρητικότητα οδικού τμήματος (v/c)",
  "Εξειδικευμένη πλατφόρμα λήψης και ανάλυσης δεδομένων από τους υπολογιστές ιστού ανά θέση πάρκινγκ (δεν απαιτείται καμία εγκατάσταση αισθητήρων καθώς κάθε κάμερα μπορεί να εξυπηρετήσει από 50-70 θέσεις στάθμευσης). Παρέχεται η δυνατότητα δυναμικής μοντελοποίησης θέσης πάρκινγκ (στάθμευσης, απαγόρευσης, ειδικής π.χ. ΑΜΕΑ, φορτηγών, προσωρινής στάθμευσης). Η λύση διαθέτει δυνατότητα ανάλυσης δεδομένων στάθμευσης και εξαγωγής αναφορών ανά θέση, ανά ομάδα θέσεων, ανά πάρκινγκ, ανά οδό αλλά και δυνατότητα δημιουργίας κανόνων ανά θέση στάθμευσης (χρονικών, χρεώσεων, παραβάσεων)",
  "Ραντάρ μέτρησης κυκλοφορίας για ταυτόχρονη μέτρηση σε πολλές κυκλοφοριακές λωρίδες > 6, διαχείριση κυκλοφορίας πολλαπλών λωρίδων και ταυτόχρονη παρακολούθηση πολλαπλών αντικειμένων σε πραγματικό χρόνο και ταξινόμηση (Classification).",
];
//: 7 Κατηγορίες - (Πεζός, Ποδήλατο, Μοτοσικλέτα, Επιβατικό αυτοκίνητο, VAN, φορτηγό / λεωφορείο, μακρύ φορτηγό)
export const Section_1_svgIcon = SvgRegister;

export const setction_2_title =
  "Υποσύστημα διαχείρισης ελεγχόμενης στάθμευσης με χρήση υπόγειων αισθητήρων για θέσεις ΑμΕΑ και φορτοεκφόρτωσης καθώς και της σχετικής υποδομής για τη δημοτική αστυνομία";
export const setction_2_arrayTitle = "Περιλαμβάνει";
export const setction_2_array = [
  "Υπόγειους αισθητήρες ανίχνευσης κατάστασης θέσης στάθμευσης με πρωτόκολλο επικοινωνίας LoRaWan, ΝΒΙοΤ ή με RF. Στην περίπτωση χρήσης πρωτοκόλλου LoRa απαραίτητα είναι τα LoRa Gateways ενώ στην περίπτωση του RF απαραίτητοι είναι οι αναμεταδότες. Ταυτόχρονα γίνεται εγκατάσταση αισθητήρων ανίχνευσης κατάστασης θέσης στάθμευσης για ΑμΕΑ ή/και φορτοεκφόρτωσης. Περιλαμβάνει τον αισθητήρα για κάθε θέση στάθμευσης και τις κάρτες για τον κάτοχο οχήματος (η επικοινωνία ανάμεσα στην κάρτα και τον αισθητήρα γίνεται μέσω bluetooth)",
  "Εφαρμογή έκδοσης κλήσεων από τη δημοτική αστυνομία (ενημέρωση του δημοτικού αστυνόμου για παράνομη θέση στάθμευσης και δυνατότητα έκδοσης κάθε είδους κλήση από το PDA του δημοτικού αστυνόμου). Περιλαμβάνει: την εφαρμογή του δημοτικού αστυνόμου, τον φορητό εξοπλισμό (PDA και Bluetooth printer) καθώς και το κεντρικό λογισμικό διαχείρισης κλήσεων στο οποίο συλλέγονται όλες οι κλήσεις",
  "Εφαρμογή πληρωμών μέσω κινητού τηλεφώνου. Ο οδηγός μετά την ενημέρωση για τις ελεύθερες θέσεις στάθμευσης μπορεί να κάνει χρήση της ίδιας εφαρμογής για να πληρώσει το τίμημα με χρήση ηλεκτρονικού πορτοφολιού ή κάρτας αλλά και να ενημερωθεί έγκαιρα για την λήξη του χρόνου του.",
  "Αυτόματες Συσκευές Πληρωμής Τελών Στάθμευσης (παρκόμετρα). Περιλαμβάνει το παρκόμετρο καθώς και το Λογισμικό Διαχείρισης των Αυτόματων Συσκευών Πληρωμής Τελών Στάθμευσης (με κέρματα και χρήση χρεωστικής κάρτας)",
  "Εφαρμογή πληρωμής τέλους στάθμευσης μέσω φορητής συσκευής (tablet) σε σημείο πώλησης (Π.χ. περίπτερο). Περιλαμβάνει τον εξοπλισμό (Tablet) κάθε σημείου πώλησης καθώς και το λογισμικό έκδοσης εισιτηρίου.",
  "Υποσύστημα Κέντρου Ελέγχου. Περιλαμβάνει τον απαραίτητο εξοπλισμό κέντρου ελέγχου (4 οθόνες και δικτυακή υποδομή), και το κεντρικό λογισμικό (web εφαρμογή) μέσω της οποίας γίνεται η διαχείριση των θέσεων στάθμευσης, των αναφορών (reports) για κάθε οδό καθώς και το κεντρικό Dashboard με την παρακολούθηση της καθημερινής λειτουργίας του συστήματος (περιλαμβάνεται και γεωγραφική απεικόνιση για τις θέσεις στάθμευσης)",
];

export const Section_2_svgIcon = SvgManaging;

export const setction_3_title =
  "Υποσύστημα συλλογής και επεξεργασίας δεδομένων βιώσιμης κινητικότητας και παρακολούθησης δεικτών ΣΒΑΚ";
export const setction_3_arrayTitle =
  "Το Υποσύστημα θα αντλεί δεδομένα από τον εξοπλισμό που είναι εγκατεστημένος στο οδικό δίκτυο της πόλης (κάμερες επί του ιστού). Τα δεδομένα αυτά, σε συνδυασμό με τα ιστορικά δεδομένα που συλλέχτηκαν από το ΣΒΑΚ και άλλες παρόμοιες μελέτες, (κοινωνικοοικονομικά, πολεοδομικά, κυκλοφοριακά, οικονομικά κλπ) και τα αποτελέσματα μελετών, σχεδίων και αναπτυξιακών πλάνων (ΠΕΠ, ΟΧΕ, ΣΒΑΚ, τοπικά αναπτυξιακά, ΓΠΣ κλπ) θα δίνουν μια καλή εικόνα της υφιστάμενης κατάστασης ενώ συγχρόνως θα αναδεικνύουν τα προβλήματα και θα προτείνουν τις κατάλληλες λύσεις. Περιλαμβάνει:";
export const setction_3_array = [
  "Την ανάπτυξη κεντρικού κόμβου ως παρατηρητηρίου για τον υπολογισμό δεικτών για τη διαχρονική παρακολούθηση της περιβαλλοντικής επιβάρυνσης που επιφέρουν οι μεταφορές στην πόλη και την ευρύτερη περιοχή, αλλά και όλων των δεικτών που όρισε το ΣΒΑΚ ώστε να δείχνει την υφιστάμενη κατάσταση και την επίπτωση των παρεμβάσεων και των έργων που θα υλοποιούνται σταδιακά, στους στόχους και στο όραμα της πόλης. Οι τιμές των δεικτών θα αποτελούν και βάση για τον αναπτυξιακό προγραμματισμό και των παρεμβάσεων στον ιστό της πόλης.",
  "On line εργαλείο δημιουργίας αναφορών με βάση τους δείκτες και τα μεγέθη του ΣΒΑΚ για συγκεκριμένα χρονικά διαστήματα.",
];

export const Section_3_svgIcon = SvgCollection;

export const setction_4_title =
  "Υποσύστημα πληροφόρησης οδηγών για ελεύθερες θέσεις στάθμευσης και συνθήκες κυκλοφορίας";
export const setction_4_arrayTitle = "Περιλαμβάνει";
export const setction_4_array = [
  "Mobile app για την ενημέρωση των οδηγών σε πραγματικό χρόνο για τις ελεύθερες θέσεις στάθμευσης καθώς και πληρωμής θέσεων παρόδιας στάθμευσης. Εναλλακτικά θα μπορούσε να γίνει χρήση Mobile εφαρμογής μέσω της οποίας ο χρήστης μπορεί να επιβεβαιώσει τη θέση που του προτείνει το σύστημα ως ελεύθερη και εν συνεχεία να πληρώσει από το κινητό του τηλέφωνο (σε αυτή την περίπτωση δεν απαιτείται εγκατάσταση αισθητήρων)",
  "Διαδικτυακή πύλης (web portal) παρουσίασης των ελεύθερων θέσεων στάθμευσης για τους πολίτες με χρήση γεωγραφικών συστημάτων πληροφοριών.",
  "Ηλεκτρονικές Πινακίδες πληροφόρησης οδηγών οι οποίες ενημερώνουν τους οδηγούς για τις ελεύθερες θέσεις στάθμευσης σε διαφορετικούς δρόμους της πόλης.",
  "Ανοικτή διάθεση δεδομένων στάθμευσης και κυκλοφορίας (Open Data API) για χρήση από τρίτα συστήματα.",
];

export const Section_4_svgIcon = SvgInformation;

export const setction_5_title =
  "Δημιουργία ολοκληρωμένης παρέμβασης με εγκατάσταση Κέντρου Διαχείρισης και Παρακολούθησης της Αστικής Κινητικότητας";
export const setction_5_arrayTitle =
  "Περιλαμβάνει το σύνολο του απαραίτητου κεντρικού λογισμικού στο οποίο οι χειριστές μπορούν να παρακολουθούν το σύνολο των υποσυστημάτων, την καθημερινή λειτουργία των αισθητήρων, των καμερών σε πραγματικό χρόνο (online), των παραβάσεων, τις θέσεις των ΑμΕΑ και της φορτοεκφόρτωσης με χρήση ενός ενιαίου γεωγραφικού πληροφοριακού συστήματος. Το Κέντρο Ελέγχου θα παρέχει λειτουργίες διαχείρισης συμβάντων (alerts) και θα είναι προσβάσιμο από συγκεκριμένες κατηγορίες χρηστών.";
export const Section_5_svgIcon = SvgInterfere;

export const basicFunc2 = <>Σε ποιούς απευθύνεται η πλατφόρμα {appNameTitle}</>;
export const basicFuncArray2 = [
  {
    title: "Σε Δημότες και επισκέπτες",
    svgIcon: SvgCitizenVisitors,
  },
  {
    title: "Σε ΑμεΑ",
    svgIcon: SvgAmea2,
  },
  {
    title: "Σε επαγγελματίες (Λύση για φορτοεκφόρτωση)",
    svgIcon: SvgCeo,
  },
  {
    title: "Στη Δημοτική Αστυνομία",
    svgIcon: SvgPolice2,
  },
  {
    title: "Στη Διοίκηση του Δήμου",
    svgIcon: SvgMunicipality,
  },
];

export const recommendedProjects = "Ενδεικτικά Έργα";

export const appName = "easyparking";

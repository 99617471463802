import React from "react";
import { useTranslation } from "react-i18next";

const Functionality = ({ list }) => {
  const { t } = useTranslation();
  return (
    <div className="wrapper">
      {list.map((item, index) => (
        <div
          key={index}
          className={
            item.box === "large"
              ? "wrapper-content large-box"
              : "wrapper-content"
          }
        >
          {/*<img src={item.imageUrl} alt="" />*/}
          {item.svgIcon && <item.svgIcon />}
          <div>
            <h4>{t(item.title)}</h4>
            {item.list && (
              <ul>
                {item.list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Functionality;

import * as React from "react";

const SvgPolice = (props) => (
  <svg
    id="Police_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>
        {".Police_svg__cls-1{fill:#5a6e7f}.Police_svg__cls-2{fill:#97d5c9}"}
      </style>
    </defs>
    <path
      className="Police_svg__cls-1"
      d="M40.78 69.21h-21.9a6.28 6.28 0 0 1-6.27-6.27V11.59a6.27 6.27 0 0 1 6.27-6.26h11a.54.54 0 0 1 .54.54.54.54 0 0 1-.54.54h-11a5.19 5.19 0 0 0-5.19 5.18v51.35a5.2 5.2 0 0 0 5.19 5.19h21.9A5.2 5.2 0 0 0 46 62.94V31.25a.54.54 0 0 1 1.08 0v31.69a6.29 6.29 0 0 1-6.3 6.27Z"
    />
    <path
      className="Police_svg__cls-1"
      d="M32.74 14H13.15a.54.54 0 0 1-.54-.54.54.54 0 0 1 .54-.54h19.59a.54.54 0 0 1 .54.54.54.54 0 0 1-.54.54ZM46.51 61.38H13.15a.54.54 0 0 1 0-1.08h33.36a.54.54 0 1 1 0 1.08ZM33.33 65.29h-7a.54.54 0 1 1 0-1.07h7a.54.54 0 1 1 0 1.07Z"
    />
    <path
      className="Police_svg__cls-2"
      d="M42.62 27.34H17.89v-9.52h18.67v.72a9.41 9.41 0 0 0 6.06 8.8ZM17.89 32.11h9.53v9.53h-9.53zM33.22 32.11h9.53v9.53h-9.53zM42.75 47H17.89a.54.54 0 0 1 0-1.08h24.86a.54.54 0 0 1 0 1.08ZM42.75 51.18H17.89a.54.54 0 0 1 0-1.08h24.86a.54.54 0 0 1 0 1.08ZM42.75 55.41H17.89a.54.54 0 0 1 0-1.08h24.86a.54.54 0 0 1 0 1.08Z"
    />
    <path
      className="Police_svg__cls-1"
      d="M45.94 28.49a9.8 9.8 0 0 1-3.52-.64A10 10 0 0 1 36 18.54v-.77a10 10 0 0 1 .26-1.61.53.53 0 0 1 .65-.39.54.54 0 0 1 .4.65 8.81 8.81 0 0 0-.23 1.44v.68a8.93 8.93 0 0 0 5.74 8.3 8.72 8.72 0 0 0 3.13.57 8.87 8.87 0 0 0 8.62-11 .55.55 0 0 1 .4-.66.54.54 0 0 1 .65.4 10 10 0 0 1-9.67 12.34Z"
    />
    <path
      className="Police_svg__cls-1"
      d="M45.9 18h-.07l-10.95-1.42a.53.53 0 0 1-.44-.7l1.69-5.22-1.69-5.21a.54.54 0 0 1 .33-.68L45.72.83a.45.45 0 0 1 .36 0L57 4.77a.54.54 0 0 1 .33.68l-1.69 5.21 1.69 5.22a.53.53 0 0 1-.44.7L46 18Zm-10.24-2.4 10.24 1.34 10.24-1.34-1.45-4.46a.53.53 0 0 1-.16-.38v-.19a.53.53 0 0 1 .16-.38l1.49-4.58-10.28-3.7-10.27 3.7 1.48 4.58a.54.54 0 0 1 .15.47.55.55 0 0 1-.15.48Z"
    />
    <path
      className="Police_svg__cls-1"
      d="M36.66 11.33a.54.54 0 0 1-.19-1 27.11 27.11 0 0 1 18.79-.06h.07a.54.54 0 0 1-.39 1 26.1 26.1 0 0 0-18 .06Z"
    />
    <circle className="Police_svg__cls-1" cx={45.95} cy={6.32} r={1.32} />
  </svg>
);

export default SvgPolice;

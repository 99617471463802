import * as React from "react";

const SvgParking = (props) => (
  <svg
    id="Parking_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>
        {
          ".Parking_svg__cls-1{fill:#5a6e7f}.Parking_svg__cls-2{fill:#97d5c9}.Parking_svg__cls-3{fill:#fff}"
        }
      </style>
    </defs>
    <rect
      className="Parking_svg__cls-1"
      x={6.63}
      y={25.58}
      width={14.06}
      height={14.06}
      rx={3.32}
    />
    <rect
      className="Parking_svg__cls-2"
      x={7.34}
      y={26.29}
      width={12.58}
      height={12.58}
      rx={2.61}
    />
    <path
      className="Parking_svg__cls-3"
      d="M10.76 28.71a14.17 14.17 0 0 1 2.44-.17 3.87 3.87 0 0 1 2.44.64A2.36 2.36 0 0 1 16.5 31a2.58 2.58 0 0 1-.74 1.9 3.71 3.71 0 0 1-2.59.84 3.64 3.64 0 0 1-.62 0v2.86h-1.79Zm1.79 3.62a2.43 2.43 0 0 0 .6.05c1 0 1.56-.48 1.56-1.3s-.51-1.17-1.41-1.17a2.89 2.89 0 0 0-.75.07Z"
    />
    <rect
      className="Parking_svg__cls-1"
      x={27.84}
      y={25.58}
      width={14.06}
      height={14.06}
      rx={3.32}
    />
    <rect
      className="Parking_svg__cls-2"
      x={28.55}
      y={26.29}
      width={12.58}
      height={12.58}
      rx={2.61}
    />
    <path
      className="Parking_svg__cls-3"
      d="M32 28.71a14.08 14.08 0 0 1 2.44-.17 3.87 3.87 0 0 1 2.44.64A2.35 2.35 0 0 1 37.7 31a2.58 2.58 0 0 1-.73 1.9 3.72 3.72 0 0 1-2.6.84 3.59 3.59 0 0 1-.61 0v2.86H32Zm1.79 3.62a2.43 2.43 0 0 0 .6.05c1 0 1.55-.48 1.55-1.3s-.51-1.17-1.41-1.17a2.85 2.85 0 0 0-.74.07Z"
    />
    <rect
      className="Parking_svg__cls-1"
      x={49.05}
      y={25.58}
      width={14.06}
      height={14.06}
      rx={3.32}
    />
    <rect
      className="Parking_svg__cls-2"
      x={49.76}
      y={26.29}
      width={12.58}
      height={12.58}
      rx={2.61}
    />
    <path
      className="Parking_svg__cls-3"
      d="M53.18 28.71a14 14 0 0 1 2.44-.17 3.89 3.89 0 0 1 2.44.64 2.35 2.35 0 0 1 .85 1.82 2.58 2.58 0 0 1-.73 1.9 3.72 3.72 0 0 1-2.6.84 3.52 3.52 0 0 1-.61 0v2.86h-1.79ZM55 32.33a2.43 2.43 0 0 0 .6.05c1 0 1.55-.48 1.55-1.3s-.51-1.17-1.41-1.17A2.8 2.8 0 0 0 55 30Z"
    />
    <path
      className="Parking_svg__cls-2"
      d="M68.83 25a.58.58 0 0 1-.59-.59v-5.56A14.5 14.5 0 0 0 53.76 4.37H20a.59.59 0 1 1 0-1.18h33.76a15.68 15.68 0 0 1 15.66 15.66v5.52a.58.58 0 0 1-.59.63Z"
    />
    <path
      className="Parking_svg__cls-1"
      d="M68.83 49.78H1.17a.58.58 0 0 1-.59-.59V3.78a.58.58 0 0 1 .59-.59h13.2a.59.59 0 0 1 0 1.18H1.76V48.6h66.48V30.35a.59.59 0 1 1 1.18 0v18.84a.58.58 0 0 1-.59.59ZM59.46 66.81H10.54a.59.59 0 0 1-.6-.59 8.55 8.55 0 0 1 8.54-8.54h33a8.55 8.55 0 0 1 8.54 8.54.59.59 0 0 1-.56.59Zm-48.31-1.18h47.7a7.38 7.38 0 0 0-7.33-6.77h-33a7.38 7.38 0 0 0-7.37 6.77Z"
    />
    <path
      className="Parking_svg__cls-1"
      d="M34.87 16.61a.59.59 0 0 1-.59-.59V9.1a.59.59 0 1 1 1.18 0V16a.59.59 0 0 1-.59.61ZM13.66 24.45a.59.59 0 0 1-.59-.6V16a.59.59 0 0 1 1.18 0v7.84a.59.59 0 0 1-.59.61Z"
    />
    <path
      className="Parking_svg__cls-1"
      d="M34.87 24.45a.59.59 0 0 1-.59-.6V16a.59.59 0 0 1 1.18 0v7.84a.59.59 0 0 1-.59.61ZM56.08 24.45a.6.6 0 0 1-.6-.6V16a.6.6 0 0 1 1.19 0v7.84a.6.6 0 0 1-.59.61Z"
    />
    <path
      className="Parking_svg__cls-1"
      d="M56.08 16.6H13.66a.59.59 0 0 1 0-1.18h42.42a.59.59 0 1 1 0 1.18ZM40.51 58.86h-11a.59.59 0 0 1-.59-.59v-6.41a.6.6 0 0 1 1.19 0v5.82h9.82v-8.49a.6.6 0 0 1 1.19 0v9.08a.59.59 0 0 1-.61.59Z"
    />
  </svg>
);

export default SvgParking;

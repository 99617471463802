import React from "react";
import "../css/clients.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//Import img

import dimos_agriniou from "./../img/dimos_agriniou.png";
import dimos_ioannina from "./../img/dimos_ioannina.png";
import dimos_pellas from "./../img/dimos_pellas.png";
import dimos_kalamaria from "./../img/dimos_kalamaria.png";
import dimos_thermis from "./../img/dimos_thermis.png";
import dimos_alexandrias from "./../img/dimos_alexandrias.png";


import { useTranslation } from "react-i18next";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 5, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
};

const Clients = () => {
  const { t } = useTranslation();
  return (
    <div className="clients-container">
      <h2>{t("Πελάτες")}</h2>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        autoPlaySpeed={3500}
        autoPlay={true}
        infinite={true}
      >
        <img src={dimos_agriniou} alt="dimos agriniou" />
        <img src={dimos_ioannina} alt="dimos ioannina" />
        <img src={dimos_pellas} alt="dimos pellas" />
        <img src={dimos_kalamaria} alt="dimos kalamaria" />
        <img src={dimos_thermis} alt="dimos thermis" />
        <img src={dimos_alexandrias} alt="dimos alexandrias" />

      </Carousel>
    </div>
  );
};
export default Clients;

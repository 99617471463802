import * as React from "react";

const SvgIndexes = (props) => (
  <svg
    id="Indexes_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>
        {".Indexes_svg__cls-1{fill:#97d5c9}.Indexes_svg__cls-2{fill:#5a6e7f}"}
      </style>
    </defs>
    <path
      className="Indexes_svg__cls-1"
      d="M56.49 23.01h8.54v40.33h-8.54zM44.27 39.05h8.54v24.28h-8.54zM32.06 47.15h8.54v16.19h-8.54zM19.84 52.59h8.54v10.74h-8.54zM7.63 56.42h8.54v6.92H7.63z"
    />
    <path
      className="Indexes_svg__cls-2"
      d="M69.44 68H.56a.56.56 0 1 1 0-1.12h68.88a.56.56 0 0 1 0 1.12ZM5 50.8a.56.56 0 0 1-.55-.57.55.55 0 0 1 .55-.55h.07a55.62 55.62 0 0 0 22.1-5.54c10.91-5.21 25.52-16.53 33.05-40.87a.56.56 0 0 1 1.07.33c-7.66 24.75-22.56 36.27-33.7 41.57A56.58 56.58 0 0 1 5 50.8Z"
    />
    <path
      className="Indexes_svg__cls-2"
      d="M66 12.76a.55.55 0 0 1-.49-.3L60.8 3.31 52 8.06a.57.57 0 0 1-.76-.23.56.56 0 0 1 .23-.76l9.27-5a.54.54 0 0 1 .43 0 .55.55 0 0 1 .34.27l5 9.66a.56.56 0 0 1-.24.76.58.58 0 0 1-.27 0Z"
    />
  </svg>
);

export default SvgIndexes;

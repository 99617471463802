import * as React from "react";

const SvgCeo = (props) => (
  <svg
    id="CEO_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 1200 1200"
    style={{
      enableBackground: "new 0 0 1200 1200",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".CEO_svg__st0{fill:#5a6e7f}"}</style>
    <path
      className="CEO_svg__st0"
      d="M834.4 316.9H492.7c-25.5 0-45.9 20.4-45.9 45.9v272.8h433.4V362.8c.1-24.2-20.3-45.9-45.8-45.9zM676.3 610.1h-38.2V374.3c0-10.2 7.6-17.8 17.8-17.8h2.5c10.2 0 17.8 7.6 17.8 17.8v235.8zm114.7 0h-38.2V374.3c0-10.2 8.9-19.1 19.1-19.1 10.2 0 19.1 8.9 19.1 19.1v235.8zM262 472.4 58 612.6l137.7 198.9 204-140.2L262 472.4zm1.3 263.9-40.8 28-104.5-153 40.8-28 104.5 153zm81.6-56.1-40.8 28-104.6-151.6 40.8-28 104.6 151.6zM64.4 930.1l25.5 38.2 356.9-242.2v-63.7L64.4 930.1z"
    />
    <path
      className="CEO_svg__st0"
      d="M1071.5 431.6H905.8v229.5h-459v89.2c0 38.2 25.5 38.2 25.5 38.2h51c0-89.2 102-89.2 102-89.2 102 0 102 89.2 102 89.2h153c0-89.2 102-89.2 102-89.2 102 0 102 89.2 102 89.2h38.2c25.5 0 25.5-12.7 25.5-12.7v-255c0-63.7-76.5-89.2-76.5-89.2zM854.8 750.3H740v-25.5h114.7v25.5zM1097 622.8h-63.7c0-51-38.2-51-38.2-51h-51v-89.2h102c51 0 51 38.2 51 38.2v102z"
    />
    <path
      className="CEO_svg__st0"
      d="M625.3 724.8c-42.1 0-76.5 34.4-76.5 76.5s34.4 76.5 76.5 76.5 76.5-34.4 76.5-76.5-34.4-76.5-76.5-76.5zm0 114.8c-21.7 0-38.2-16.6-38.2-38.2 0-21.7 16.6-38.2 38.2-38.2 21.7 0 38.2 16.6 38.2 38.2.1 21.6-16.5 38.2-38.2 38.2zM982.3 724.8c-42.1 0-76.5 34.4-76.5 76.5s34.4 76.5 76.5 76.5 76.5-34.4 76.5-76.5-34.5-76.5-76.5-76.5zm0 114.8c-21.7 0-38.2-16.6-38.2-38.2 0-21.7 16.6-38.2 38.2-38.2 21.7 0 38.2 16.6 38.2 38.2s-16.6 38.2-38.2 38.2z"
    />
  </svg>
);

export default SvgCeo;

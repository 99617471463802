import * as React from "react";

const SvgEBills = (props) => (
  <svg
    id="EBills_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>{".EBills_svg__cls-1{fill:#5a6e7f}"}</style>
    </defs>
    <path
      className="EBills_svg__cls-1"
      d="M23.84 58.84A23.84 23.84 0 1 1 36.2 14.61l-.63 1a22.63 22.63 0 1 0 0 38.72l.63 1a23.81 23.81 0 0 1-12.36 3.51Z"
    />
    <path
      className="EBills_svg__cls-1"
      d="M23.84 52.41a17.41 17.41 0 0 1 0-34.82 17.24 17.24 0 0 1 12.48 5.27l-.87.84a16.2 16.2 0 1 0 0 22.6l.87.84a17.24 17.24 0 0 1-12.48 5.27Z"
    />
    <path
      className="EBills_svg__cls-1"
      d="M35.28 7.81h1.21V62.2h-1.21zM42.3 12.64h8.9v1.21h-8.9zM55.73 8.66h10.14v1.21H55.73zM60.76 16.6H70v1.21h-9.24zM45.91 28.71h9.01v1.21h-9.01zM57.71 33.16h6.18v1.21h-6.18zM44.78 44.3h10.14v1.21H44.78zM60.76 44.3h3.03v1.21h-3.03zM42.3 59.65h4.42v1.21H42.3zM55.73 57.43h5.07v1.21h-5.07z"
    />
    <path
      d="M30.44 44.46a11 11 0 0 1-5.09 1.25 9.83 9.83 0 0 1-8-3.73 9.15 9.15 0 0 1-1.83-4.24h-2.09v-2.32h1.79v-1.56h-1.79v-2.3h2.18a10.57 10.57 0 0 1 2.26-4.47 9.88 9.88 0 0 1 7.54-3.33 12.44 12.44 0 0 1 4.9 1l-.91 3.72a9.65 9.65 0 0 0-3.63-.78 5 5 0 0 0-4 1.76 5.54 5.54 0 0 0-1 2.12h8.13v2.32h-8.68v1.52h8.65v2.32h-8.2A5.11 5.11 0 0 0 21.72 40a5.46 5.46 0 0 0 4.21 1.67 9.07 9.07 0 0 0 3.73-.85Z"
      style={{
        fill: "#97d5c9",
      }}
    />
  </svg>
);

export default SvgEBills;

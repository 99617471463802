import * as React from "react";

const SvgConnections = (props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <path
      d="M49.62 42.57a10.36 10.36 0 0 1-3.12 7L30 66.08a10.33 10.33 0 0 1-7.33 3.13 8.69 8.69 0 0 1-6.28-2.56L7 57.3a8.86 8.86 0 0 1-2.6-6.72 10.17 10.17 0 0 1 3.09-6.85l5.65-5.66a16.7 16.7 0 0 0 1.51 2l-5.33 5.36A7.6 7.6 0 0 0 7 50.71a6.22 6.22 0 0 0 1.75 4.79l9.36 9.35c2.58 2.57 7.19 2.31 10.07-.57L44.7 47.77c2.88-2.89 3.14-7.5.57-10.07l-9.36-9.36a1.24 1.24 0 0 1-.39-.91 1.06 1.06 0 0 1 .34-.77 1.17 1.17 0 0 1 .9-.36 1.29 1.29 0 0 1 .91.36l9.4 9.34a8.77 8.77 0 0 1 2.55 6.57Z"
      style={{
        fill: "#5a6e7f",
      }}
    />
    <path
      d="M62.61 26.31 57 32a19.74 19.74 0 0 0-1.52-2.05l5.28-5.4c2.88-2.89 3.15-7.5.57-10.07l-9.4-9.41c-2.57-2.57-7.18-2.31-10.07.57L25.35 22.16l-.06.06A8.42 8.42 0 0 0 23 27.35a6.33 6.33 0 0 0 1.75 4.87l9.36 9.44.06.05a1.06 1.06 0 0 1 .34.77 1.29 1.29 0 0 1-.4.91 1.27 1.27 0 0 1-.89.35 1.21 1.21 0 0 1-.91-.35L23 34a8.81 8.81 0 0 1-2.55-6.35 10.34 10.34 0 0 1 3.13-7.25L40.06 3.92a10.36 10.36 0 0 1 7-3.12h.32a8.77 8.77 0 0 1 6.29 2.57l9.41 9.33c3.56 3.61 3.34 9.73-.47 13.61Z"
      style={{
        fill: "#97d5c9",
      }}
    />
  </svg>
);

export default SvgConnections;

import * as React from "react";

const SvgMobileApp = (props) => (
  <svg
    id="MobileApp_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>
        {
          ".MobileApp_svg__cls-1{fill:#5a6e7f}.MobileApp_svg__cls-2{fill:#97d5c9}"
        }
      </style>
    </defs>
    <path
      className="MobileApp_svg__cls-1"
      d="M49.5 39.81a.51.51 0 0 0-.57.5v22.43a5.64 5.64 0 0 1-5.66 5.65H19.59a5.64 5.64 0 0 1-5.66-5.65V7.26a5.64 5.64 0 0 1 5.66-5.65H42a.53.53 0 0 0 .54-.54.52.52 0 0 0-.54-.53H19.59a6.74 6.74 0 0 0-6.73 6.72v55.48a6.74 6.74 0 0 0 6.73 6.72h23.68A6.75 6.75 0 0 0 50 62.74V40.31a.66.66 0 0 0-.5-.5Z"
    />
    <path
      className="MobileApp_svg__cls-1"
      d="M13.4 8.75h-.08a.56.56 0 0 0-.5.58.58.58 0 0 0 .58.49h34.53a.52.52 0 0 0 .54-.53.53.53 0 0 0-.54-.54ZM49.42 61h-36a.54.54 0 1 1 0-1.07h36.04a.52.52 0 0 1 .54.53.56.56 0 0 1-.58.54ZM35.21 65.26h-7.56a.51.51 0 0 1-.58-.5.51.51 0 0 1 .5-.57h7.64a.5.5 0 0 1 .58.49.52.52 0 0 1-.5.58Z"
    />
    <path
      className="MobileApp_svg__cls-2"
      d="M51.22 11.5a4.54 4.54 0 0 1-3.63-1.37A4.64 4.64 0 0 1 46.52 7a4.27 4.27 0 0 1 1.15-3.06 4.79 4.79 0 0 1 3.59-1.22A5.58 5.58 0 0 1 55 3.83 4.07 4.07 0 0 1 56.3 7a4.33 4.33 0 0 1-1.3 3.36 5 5 0 0 1-3.78 1.14ZM46.94 14.6h8.94v22.27h-8.94z"
    />
    <path
      className="MobileApp_svg__cls-2"
      d="M43.81 35.8h13a.36.36 0 0 1 .38.38v.35a.32.32 0 0 1-.31.3H43.69a.34.34 0 0 1-.34-.34v-.19a.54.54 0 0 1 .46-.5ZM42.43 14.6H55.5a.36.36 0 0 1 .38.38v.65H42.51a.46.46 0 0 1-.46-.46V15a.38.38 0 0 1 .38-.38Z"
    />
    <path
      className="MobileApp_svg__cls-1"
      d="M14.24 50.51h7.07a.79.79 0 0 0 .61-.27.9.9 0 0 0 .26-.61v-7.07a.78.78 0 0 0-.26-.61.94.94 0 0 0-.61-.26H14a.88.88 0 1 0 0 1.75h6.46v5.31h-6.22a.88.88 0 1 0 0 1.76ZM26.12 39.05H39a1 1 0 0 0 .77-.31 1.12 1.12 0 0 0 .3-.76v-8.67a1.07 1.07 0 0 0-.3-.77 1.14 1.14 0 0 0-.77-.3H27.19a1.06 1.06 0 0 0-1.07 1.07 1.1 1.1 0 0 0 1.07 1.07h10.7v6.49H26.08A1.07 1.07 0 0 0 25 37.94a1.14 1.14 0 0 0 1.11 1.11ZM13.74 54.94h6.88V60a.88.88 0 1 0 1.76 0v-6a.77.77 0 0 0-.27-.61.9.9 0 0 0-.61-.26h-7.76a.88.88 0 1 0 0 1.75ZM25.93 60.48a.73.73 0 0 0 .61-.27.91.91 0 0 0 .27-.61v-5.08H38.5l1.41 5.35a.88.88 0 0 0 1.11.5.86.86 0 0 0 .53-1.11l-1.49-5.62a.76.76 0 0 0-.26-.64.94.94 0 0 0-.61-.26H25.93a.86.86 0 0 0-.88.87v6a.76.76 0 0 0 .27.61.86.86 0 0 0 .61.27ZM43.81 53.19a.88.88 0 0 0-.73.38 1 1 0 0 0-.11.8l1.6 6a.85.85 0 0 0 1.11.5.87.87 0 0 0 .54-1.11L45 54.94h3.82a.88.88 0 1 0 0-1.75ZM26.77 50.51h22.08a.88.88 0 1 0 0-1.76h-21.2v-5.31h21.09a.88.88 0 1 0 0-1.75h-22a.86.86 0 0 0-.88.87v7.07a.93.93 0 0 0 .88.88ZM36.7 9.14a.75.75 0 0 0-.61.26 1 1 0 0 0-.27.61v3.56H24.13l-1.41-3.82a.88.88 0 0 0-1.11-.5.86.86 0 0 0-.53 1.11l1.49 4.09a.76.76 0 0 0 .26.61.94.94 0 0 0 .62.26H36.7a.86.86 0 0 0 .88-.87V10a.79.79 0 0 0-.27-.61.9.9 0 0 0-.61-.26ZM19.2 15.32a.86.86 0 0 0 .73-.38 1 1 0 0 0 .12-.8l-1.61-4.43a.85.85 0 0 0-1.11-.5.86.86 0 0 0-.53 1.11l1.2 3.25h-3.84a.88.88 0 1 0 0 1.75ZM36.24 17.62H14.16a.88.88 0 1 0 0 1.75h21.21v5.31H14.28a.88.88 0 0 0 0 1.76h22a.86.86 0 0 0 .88-.88V18.5a.89.89 0 0 0-.88-.88Z"
    />
    <path
      className="MobileApp_svg__cls-2"
      d="M24.59 28.81a.24.24 0 0 1 .12.23v.08L22.26 39.2a.23.23 0 0 1-.23.19.25.25 0 0 1-.27-.15l-2-4.43-.38-.12L15 33.51a.24.24 0 0 1-.19-.23.26.26 0 0 1 .16-.27l5.84-2.63 3.55-1.61h.12c.04 0 .07 0 .15.08"
    />
  </svg>
);

export default SvgMobileApp;

import * as React from "react";

const SvgAdditionFunctions = (props) => (
  <svg
    id="AdditionFunctions_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>{".AdditionFunctions_svg__cls-2{fill:#5a6e7f}"}</style>
    </defs>
    <path
      d="M69.74 12.57v33.64a6.71 6.71 0 0 1-5 6.48v-2.3a4.54 4.54 0 0 0 2.81-4.18V12.57A4.52 4.52 0 0 0 63 8.06H18.18a4.53 4.53 0 0 0-4.18 2.8h-2.3a6.7 6.7 0 0 1 6.48-5H63a6.72 6.72 0 0 1 6.74 6.71Z"
      style={{
        fill: "#97d5c9",
      }}
    />
    <path
      className="AdditionFunctions_svg__cls-2"
      d="M58.52 23.79v33.64a6.71 6.71 0 0 1-6.7 6.7H20v-2.19h31.82a4.51 4.51 0 0 0 4.51-4.51V23.79a4.51 4.51 0 0 0-4.51-4.51H7a4.52 4.52 0 0 0-4.51 4.51v20.29H.26V23.79A6.71 6.71 0 0 1 7 17.09h44.82a6.69 6.69 0 0 1 6.7 6.7Z"
    />
    <path
      className="AdditionFunctions_svg__cls-2"
      d="M21.34 51.48v1.82h-8.72v9.24h-2.21l.01-9.24H1.74v-1.82h8.67v-9.2h2.21v9.2h8.72z"
    />
  </svg>
);

export default SvgAdditionFunctions;

import * as React from "react";

const SvgInformation = (props) => (
  <svg
    id="Information_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 223.66 296.37"
    {...props}
  >
    <defs>
      <style>
        {
          ".Information_svg__cls-1{fill:#97d5c9}.Information_svg__cls-1,.Information_svg__cls-3{fill-rule:evenodd}.Information_svg__cls-3,.Information_svg__cls-5{fill:#5a6e7f}.Information_svg__cls-4{fill:none}"
        }
      </style>
    </defs>
    <path
      className="Information_svg__cls-1"
      d="M132.43 170.28c6.82 14.78 17.41 17 27.7 21.74 22.6 10.46 46.55 15.52 51.79 44.39 4.16 22.94-20.75 41.15-39.47 48.48-6.58 2.57-13.89 4.07-20.94 2.82-21-3.71-26.5-24.75-39.58-37.92-19.2-19.34-49.23-25.91-61.91-52-8.25-17-3-35.68 10.38-48.47 5.18-5 12.54-8.47 19.17-11 11.2-4.33 25.27-5.36 36.75 6.53 5.68 5.85 13.59 19.96 16.11 25.43Z"
    />
    <path
      d="M123.78 96.65c1 12.41 7.33 23 9.38 26a34.2 34.2 0 0 0 7.45 8.06c6.77 5 14.94 6.12 23.11 6.48a52.57 52.57 0 0 0 19.74-2.4c13.78-4.74 22.39-16.59 23.25-31a45.41 45.41 0 0 0-1.38-14.27c-4.83-18.54-23.12-36.06-43-35.31-7.8.29-14.9 4.44-20.86 9.18-10.63 8.41-18.82 19.36-17.69 33.26Z"
      style={{
        fill: "#ffd156",
        fillRule: "evenodd",
      }}
    />
    <path
      className="Information_svg__cls-3"
      d="M134.44 96.45c-14.1 4.2-26.56 8.3-28.24 18C103.33 131 105 151.86 86.23 160A22.76 22.76 0 0 1 76 161.7c-20.2-1.37-28.84-23.67-29.81-41C45 98.81 54.38 77.29 68.42 61c8-9.29 19.26-16.74 31.64-18.24 17.22-2.08 35.92 5 43.94 21 6.53 12.91 5 28.38-9.56 32.69Z"
    />
    <path
      className="Information_svg__cls-1"
      d="M19.05 33.32c-8.31 3.77-10 14.81-9.32 23.91A112.75 112.75 0 0 0 18.5 93.7c2.6 6.09 6.09 12.31 12 15.3a23.21 23.21 0 0 0 11.08 2.11c12.58-.32 24.16-6.5 35.2-12.54 8.82-4.82 18.38-10.51 21.45-20.09 2.13-6.64.65-13.93 2.08-20.75 2.09-9.89 10.19-18.58 8.94-28.61a8 8 0 0 0-2.6-5.43c-1.67-1.33-3.94-1.53-6.07-1.64-26-1.32-50.44 4.81-76 9.57a24.6 24.6 0 0 0-5.51 1.7Z"
    />
    <path
      className="Information_svg__cls-4"
      d="M99.55 17.96h-3.02v1.26l29.17 29.17h1.25v-3.03l-27.4-27.4zM96.53 39.44l8.95 8.95h4.29L96.53 35.15v4.29zM126.95 20.08l-2.12-2.12h-4.29l6.41 6.42v-4.3zM96.53 24.27l24.11 24.12h4.3L96.53 19.98v4.29zM96.53 34.38l14 14.01h4.29l-18.29-18.3v4.29zM96.53 29.33l19.06 19.06h4.29L96.53 25.04v4.29zM126.95 17.96h-1.35l1.35 1.36v-1.36zM126.95 35.25l-17.29-17.29h-4.29l21.58 21.58v-4.29zM126.95 40.31l-22.34-22.35h-4.29l26.63 26.64v-4.29zM126.95 30.2l-12.23-12.24h-4.29l16.52 16.53V30.2zM126.95 25.14l-7.17-7.18h-4.3l11.47 11.47v-4.29zM96.53 44.5l3.89 3.89h4.29l-8.18-8.19v4.3zM96.53 48.39h3.13l-3.13-3.13v3.13z"
    />
    <path
      className="Information_svg__cls-5"
      d="m96.53 45.26 3.13 3.13h.76l-3.89-3.89v.76zM96.53 40.2l8.18 8.19h.77l-8.95-8.95v.76zM96.53 35.15l13.24 13.24h.76l-14-14.01v.77zM96.53 30.09l18.29 18.3h.77L96.53 29.33v.76zM96.53 25.04l23.35 23.35h.76L96.53 24.27v.77zM96.53 19.98l28.41 28.41h.76L96.53 19.22v.76zM126.95 44.6l-26.63-26.64h-.77l27.4 27.4v-.76zM126.95 39.54l-21.58-21.58h-.76l22.34 22.35v-.77zM126.95 34.49l-16.52-16.53h-.77l17.29 17.29v-.76zM126.95 29.43l-11.47-11.47h-.76l12.23 12.24v-.77zM126.95 24.38l-6.41-6.42h-.76l7.17 7.18v-.76zM126.95 19.32l-1.35-1.36h-.77l2.12 2.12v-.76z"
    />
    <path
      className="Information_svg__cls-3"
      d="M113 151.3a14.86 14.86 0 0 1 3.54-4.24 7.49 7.49 0 0 1 5.65-1.76c2.38.3 3.6 1.89 4.93 3.38 1.5 1.69 3.12 3.3 6.2 3.39s4.57-1.47 6.15-3.11c1.4-1.45 2.82-3 5.63-3.06a6 6 0 0 1 4.51 2.07 14.38 14.38 0 0 1 2.73 4.14l.2.5 1-.4-.2-.5a15.32 15.32 0 0 0-3-4.49 7.13 7.13 0 0 0-5.31-2.4c-3.16.11-4.79 1.76-6.36 3.39-1.39 1.44-2.72 2.86-5.35 2.78s-4.11-1.53-5.43-3c-1.5-1.68-2.92-3.39-5.59-3.74a8.56 8.56 0 0 0-6.46 2 15.94 15.94 0 0 0-3.82 4.59l-.25.48 1 .49Z"
    />
    <path
      className="Information_svg__cls-3"
      d="M113 156.82a14.71 14.71 0 0 1 3.54-4.24 7.49 7.49 0 0 1 5.65-1.77c2.38.31 3.6 1.89 4.93 3.39 1.5 1.69 3.12 3.29 6.2 3.38s4.57-1.47 6.15-3.11c1.4-1.45 2.82-3 5.63-3.06a6.08 6.08 0 0 1 4.51 2.08 14.38 14.38 0 0 1 2.73 4.14l.2.5 1-.41-.2-.49a15.43 15.43 0 0 0-3-4.5 7.16 7.16 0 0 0-5.31-2.39c-3.16.11-4.79 1.76-6.36 3.39-1.39 1.43-2.72 2.86-5.35 2.78s-4.11-1.54-5.43-3c-1.5-1.69-2.92-3.4-5.59-3.74a8.56 8.56 0 0 0-6.46 2 15.94 15.94 0 0 0-3.82 4.59l-.25.47 1 .49Z"
    />
    <path
      className="Information_svg__cls-3"
      d="M113 162.33a14.74 14.74 0 0 1 3.54-4.23 7.45 7.45 0 0 1 5.65-1.77c2.38.3 3.6 1.89 4.93 3.39 1.5 1.69 3.12 3.29 6.2 3.38s4.57-1.47 6.15-3.11c1.4-1.45 2.82-3 5.63-3.06a6.1 6.1 0 0 1 4.51 2.07 14.38 14.38 0 0 1 2.73 4.14l.2.5 1-.4-.2-.5a15.46 15.46 0 0 0-3-4.49 7.09 7.09 0 0 0-5.31-2.39c-3.16.1-4.79 1.75-6.36 3.38-1.39 1.44-2.72 2.87-5.35 2.79s-4.11-1.54-5.43-3c-1.5-1.68-2.92-3.39-5.59-3.73a8.56 8.56 0 0 0-6.46 2 16.09 16.09 0 0 0-3.82 4.59l-.25.48 1 .49Z"
    />
    <path
      className="Information_svg__cls-5"
      d="M34.64 217.29a3.32 3.32 0 0 0-3.33 3.32v6.65h69.79v-6.65a3.31 3.31 0 0 0-3.32-3.32Zm2.49 4.15a1.67 1.67 0 1 1-1.66 1.66 1.66 1.66 0 0 1 1.66-1.66Zm5.82 0a1.67 1.67 0 1 1-1.67 1.66 1.67 1.67 0 0 1 1.72-1.66Zm5.81 0a1.67 1.67 0 1 1-1.66 1.66 1.66 1.66 0 0 1 1.66-1.66Zm-17.45 9.14v38.22h69.79v-38.22ZM179 116h-10.5v3.49h24.42V116h-10.46v-12.42h18.29V88.46h-18.29v-4.07h18.29l11.63-7.56-11.63-7.55h-40.08v15.11H179v4.07h-18.3L149.05 96l11.62 7.56H179ZM70 22.31a5 5 0 0 0-4.85 3.94H45.68a9.47 9.47 0 0 0-1.22-3.69l12.14-9a5 5 0 0 0 3.24 1.26 5.12 5.12 0 1 0-4.64-3l-12.07 9a9.71 9.71 0 1 0 0 13.31L55.2 43a5.4 5.4 0 1 0 1.4-1.79l-12.14-9a9.52 9.52 0 0 0 1.22-3.69h19.46A5 5 0 1 0 70 22.31ZM177.25 181.52l.34-1.93h-9.44l.35 1.93h8.75z"
    />
    <path
      className="Information_svg__cls-5"
      d="M177 189a5.82 5.82 0 0 1 1.69 4.09v4.61a5.54 5.54 0 0 1 1.93-.35h9.64v-12a5.79 5.79 0 0 0-5.79-5.78h-2.97l-.52 2.87v.27a3.76 3.76 0 0 1-1.42 1.93 3.9 3.9 0 0 1-2.29.71h-8.79a3.83 3.83 0 0 1-2.26-.73 3.77 3.77 0 0 1-1.4-1.91v-.27l-.58-2.87h-2.93a5.77 5.77 0 0 0-5.78 5.78v1.93h17.34A5.8 5.8 0 0 1 177 189ZM176.49 226.48a5.78 5.78 0 0 1-1.69-4.09v-4.6a5.77 5.77 0 0 1-1.93.34h-17.34v13.49a5.79 5.79 0 0 0 5.78 5.79h23.12a5.8 5.8 0 0 0 5.79-5.79v-3.45h-9.64a5.77 5.77 0 0 1-4.09-1.69Zm3.83 6.11a1.93 1.93 0 0 1-1.67 1h-11.56a1.93 1.93 0 0 1-1.67-1 2 2 0 0 1 0-1.93 1.91 1.91 0 0 1 1.67-1h11.56a1.93 1.93 0 0 1 1.67 2.89ZM159.35 197.86h-.51V200h.51c.71 0 1.38-.24 1.38-1s-.67-1.14-1.38-1.14Z"
    />
    <path
      className="Information_svg__cls-5"
      d="M159.38 194.78a5.62 5.62 0 1 0 5.62 5.61 5.62 5.62 0 0 0-5.62-5.61Zm.54 6.6h-1.08v2.93h-1.79v-7.88h2.87c1.54 0 2.7.79 2.7 2.42a2.46 2.46 0 0 1-2.7 2.53Z"
    />
    <path
      className="Information_svg__cls-5"
      d="M174.23 191.72a1.93 1.93 0 0 0-1.36-.57h-27a1.95 1.95 0 0 0-1.93 1.93v19.27a2 2 0 0 0 .57 1.37 1.92 1.92 0 0 0 1.36.56h27a1.93 1.93 0 0 0 1.93-1.93v-19.27a1.89 1.89 0 0 0-.57-1.36Zm-14.85 21.36s-8-8.22-8-12.66a8 8 0 1 1 16.08 0c-.04 4.44-8.08 12.66-8.08 12.66ZM189 213.72l1-3.7-.26-.07-5.5-.49-3 2.56c1.65 1.42 4.58 1.65 7.76 1.7ZM178.65 205.52v6.55l4.86-4.14a.64.64 0 0 1 .46-.15l2.3.2a37.19 37.19 0 0 0-7.62-2.46ZM186.78 217.88l1.05.12.71-2.63c-.73 0-1.45-.05-2.14-.1l-2 3.57 2-.91a.54.54 0 0 1 .38-.05ZM187.81 219.66l-1-.11-8 3.61a2 2 0 0 0 .41.6 1.92 1.92 0 0 0 1.36.56h7.9ZM187 206.49a.53.53 0 0 1 .25.19l1.15 1.48 1.48.13H190.11a.48.48 0 0 1-.17-.05.64.64 0 0 1-.35-.37l-2.3-6.64a.29.29 0 0 1 0-.09h-6.76a1.95 1.95 0 0 0-1.93 1.93v.73a38.34 38.34 0 0 1 8.4 2.69ZM196.59 205.89a.43.43 0 0 1-.16.14l-3.67 2.31 7.06.33a7.57 7.57 0 0 0-2.4-3.85ZM196.35 218.35l-6.7 2.87.42 2.94a.43.43 0 0 1 0 .16h9.78a1.91 1.91 0 0 0 1.93-1.93v-.85a34.66 34.66 0 0 0-5.43-3.19ZM201.66 210.41l-6.85-.32 6.15 2.62a.61.61 0 0 1 .32.31v.07l.5 1v-3.69ZM199.38 201.46a.64.64 0 0 1 0 .82l-1 1.26a8.18 8.18 0 0 1 1.86 2.18 11.41 11.41 0 0 1 1.21 3l.28-.13v-5.48a1.92 1.92 0 0 0-.56-1.36 2 2 0 0 0-1.37-.57h-.73Z"
    />
    <circle
      className="Information_svg__cls-5"
      cx={180.66}
      cy={216.1}
      r={0.83}
    />
    <path
      className="Information_svg__cls-5"
      d="m180 213.08-1.33 1.14v7.21l3.29-1.48 2.72-4.87a8.38 8.38 0 0 1-4.68-2Zm.68 5.56s-1.53-1.56-1.53-2.4a1.53 1.53 0 0 1 3.06 0c-.02.84-1.55 2.4-1.55 2.4Z"
    />
    <circle
      className="Information_svg__cls-5"
      cx={193.25}
      cy={213.94}
      r={0.83}
    />
    <path
      className="Information_svg__cls-5"
      d="m200 214.1-8.47-3.62-2.22 8.24.12.82 6.68-2.87a.64.64 0 0 1 .47 0h.05a29.27 29.27 0 0 1 5.17 2.89v-1.69Zm-6.73 2.38s-1.53-1.56-1.53-2.41a1.53 1.53 0 0 1 3.06 0c-.02.85-1.55 2.41-1.55 2.41Z"
    />
    <circle
      className="Information_svg__cls-5"
      cx={192.09}
      cy={202.98}
      r={0.83}
    />
    <path
      className="Information_svg__cls-5"
      d="m189 201.19 2.14 6.17a.45.45 0 0 1 0 .11.58.58 0 0 1 .19-.19l4.08-2.57 2.71-3.47v-.05Zm3.07 4.33s-1.53-1.56-1.53-2.41a1.53 1.53 0 0 1 3.06 0c.02.89-1.51 2.41-1.51 2.41Z"
    />
  </svg>
);

export default SvgInformation;

import React, { useContext, useEffect } from "react";

import Header from "./components/header";
import Content from "../src/components/content";
import Wrapper from "./components/wrapper";
import Description from "./components/description";
import Contact from "../src/components/contact";
import Clients from "./components/clients";
import BottomUp from "./components/bottomUp";
import Recommended from "./components/Recommended";
import {
  basicFunc,
  basicFunc2,
  basicFuncArray,
  basicFuncArray2,
  recommendedProjects,
} from "./data/staticData";
import FlexBoxes from "./components/FlexBoxes";
import cookies from "js-cookie";
import i18next from "i18next";
import LanguageContext from "./store/language-context";

const Main = () => {
  const languageCtx = useContext(LanguageContext);
  useEffect(() => {
    if (!cookies.get("i18next")) {
      i18next.changeLanguage("el").catch((error) => console.warn(error));
      languageCtx.languageCodeHandler("el");
    }
  }, []);
  return (
    <div className="content-container">
      <Header />
      <Content />
      {/*<Wrapper title={basicFunc} gridList={basicFuncArray} />*/}

      <div
        className="functionality-content  maxWidth__class"
        style={{ marginTop: 40 }}
      >
        <h2 style={{ marginBottom: "40px" }}>{basicFunc}</h2>
        <FlexBoxes>
          {basicFuncArray.slice(0, 5).map((item, index) => (
            <div
              key={index}
              className={
                item.box === "large"
                  ? "wrapper-content large-box flex_box"
                  : "wrapper-content  flex_box"
              }
            >
              {/*<img src={item.imageUrl} alt="" />*/}
              {item.svgIcon && <item.svgIcon />}
              <div>
                <h4>{item.title}</h4>
                {item.list && (
                  <ul>
                    {item.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </FlexBoxes>
        <FlexBoxes>
          {basicFuncArray.slice(5, 10).map((item, index) => (
            <div
              key={index}
              className={
                item.box === "large"
                  ? "wrapper-content large-box flex_box"
                  : "wrapper-content  flex_box"
              }
            >
              {/*<img src={item.imageUrl} alt="" />*/}
              {item.svgIcon && <item.svgIcon />}
              <div>
                <h4>{item.title}</h4>
                {item.list && (
                  <ul>
                    {item.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </FlexBoxes>
        <FlexBoxes>
          {basicFuncArray.slice(10, 15).map((item, index) => (
            <div
              key={index}
              className={
                item.box === "large"
                  ? "wrapper-content large-box flex_box"
                  : "wrapper-content  flex_box"
              }
            >
              {/*<img src={item.imageUrl} alt="" />*/}
              {item.svgIcon && <item.svgIcon />}
              <div>
                <h4>{item.title}</h4>
                {item.list && (
                  <ul>
                    {item.list.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </FlexBoxes>
      </div>
      <Description />
      <Wrapper title={basicFunc2} gridList={basicFuncArray2} />
      <Clients />
      {/*<Recommended title={recommendedProjects} />*/}
      <Contact />
      <BottomUp />
    </div>
  );
};

export default Main;

import React from "react";
import Functionality from "../components/functionality";
import "../css/wrapper.css";
import { useTranslation } from "react-i18next";

const Wrapper = ({ title, gridList }) => {
  const { t } = useTranslation();
  return (
    <div className="functionality-content  maxWidth__class" id="functionality">
      <h2>{title}</h2>
      <Functionality list={gridList} />
    </div>
  );
};
export default Wrapper;

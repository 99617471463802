import * as React from "react";

const SvgMunicipality = (props) => (
  <svg
    id="Municipality_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 700 700"
    style={{
      enableBackground: "new 0 0 700 700",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".Municipality_svg__st0{fill:#5a6e7f;stroke:#5a6e7f;stroke-width:11.3386;stroke-miterlimit:10}"
      }
    </style>
    <path
      className="Municipality_svg__st0"
      d="M567.3 541.2H132.7V347.7h433.9v193.4h.7v.1zM154 520.5h392V368.3H154v152.2z"
    />
    <path
      className="Municipality_svg__st0"
      d="M287.5 308.4v-7.7H132.8v67.7h20.6v-47.1h118c3.8-5.8 9-10.3 16.1-12.9zM567.3 368.4H546v-47.1H417.7v-20.6h149.6v67.7zM122.4 551.5h455.2v20.6H122.4v-20.6zM429.3 352.9h20.6V536h-20.6V352.9zM250.1 352.9h20.6V536h-20.6V352.9z"
    />
    <path
      className="Municipality_svg__st0"
      d="M190.8 352.9h20.6V536h-20.6V352.9zM488.6 358.1h20.6v183.1h-20.6V358.1zM339.7 133h20.6v110.9h-20.6V133z"
    />
    <path
      className="Municipality_svg__st0"
      d="M454.4 201.4H339.7v-73.5h114.8v73.5h-.1zm-94.1-21.3h73.5v-31.6h-73.5v31.6zM460.2 365.8 350 257.5 239.7 365.8 224.9 351 350 228.5 475.1 351l-14.9 14.8z"
    />
  </svg>
);

export default SvgMunicipality;

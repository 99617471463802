import * as React from "react";

const SvgAmea2 = (props) => (
  <svg
    id="AMEA2_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 700 700"
    style={{
      enableBackground: "new 0 0 700 700",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {".AMEA2_svg__st0{fill-rule:evenodd;clip-rule:evenodd;fill:#5a6e7f}"}
    </style>
    <path
      className="AMEA2_svg__st0"
      d="M105 462.4c0-62.4 32.9-115.2 84.2-143.8 12.6-7 28.7-2.5 35.7 10.2 7 12.6 2.5 28.7-10.2 35.7-35 19.5-57.3 55.1-57.3 97.9 0 62.9 51.4 114.2 115 114.2 34.6 0 65.8-15.3 87-39.5l8-10.3 2.6-4.7 1.8-2.9 2.1-5.7c4.6-13.8 19.4-21.2 33.2-16.7 13.8 4.6 21.2 19.4 16.7 33.2-2.2 6.6-3.9 10.9-6.4 15.2-2.6 4.5-2.2 3.4-4.6 7.9l-1.6 2.7c-30.1 44.3-81.2 73.4-138.8 73.4-92.2 0-167.4-74.6-167.4-166.8z"
    />
    <path
      className="AMEA2_svg__st0"
      d="m500.5 547.3-92.1-101.9h-136c-14.5 0-26.3-11.8-26.3-26.3V264.2c0-20.1 21.8-32.8 39.3-22.8L429 323.6c12.6 7.2 16.9 23.3 9.8 35.8-7.2 12.6-23.3 16.9-35.8 9.8l-104.3-59.7v83.3h121.5c7.4 0 14.5 3.1 19.5 8.6l83.2 92.1 20-16.2c11.2-9.1 27.8-7.4 36.9 3.8 9.1 11.2 7.4 27.8-3.8 36.9l-39.5 32c-10.8 8.8-26.6 7.6-36-2.7zM273.2 70.8c38.6-.5 70.4 30.4 71 69 .5 38.6-30.4 70.4-69 71-38.6.5-70.4-30.4-71-69-.5-38.6 30.3-70.4 69-71z"
    />
  </svg>
);

export default SvgAmea2;

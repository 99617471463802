import * as React from "react";

const SvgRf = (props) => (
  <svg
    id="Rf_svg__Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 70 70"
    {...props}
  >
    <defs>
      <style>{".Rf_svg__cls-2{fill:#5a6e7f}"}</style>
    </defs>
    <circle
      cx={35}
      cy={15.55}
      r={2.94}
      style={{
        fill: "#97d5c9",
      }}
    />
    <path
      className="Rf_svg__cls-2"
      d="M32.36 22.74a.43.43 0 0 1-.19 0 7.7 7.7 0 0 1 0-14.31.52.52 0 0 1 .67.29.52.52 0 0 1-.29.68 6.65 6.65 0 0 0 0 12.37.52.52 0 0 1-.19 1ZM37.64 22.74a.53.53 0 0 1-.48-.33.52.52 0 0 1 .29-.68 6.65 6.65 0 0 0 0-12.37.52.52 0 0 1-.29-.68.53.53 0 0 1 .67-.29 7.7 7.7 0 0 1 0 14.31.41.41 0 0 1-.19.04Z"
    />
    <path
      className="Rf_svg__cls-2"
      d="M41.3 26.4a.52.52 0 0 1-.27-1A11.58 11.58 0 0 0 41 5.66a.53.53 0 0 1-.17-.72.52.52 0 0 1 .71-.17 12.62 12.62 0 0 1 0 21.55.5.5 0 0 1-.24.08ZM28.7 26.4a.43.43 0 0 1-.27-.08 12.62 12.62 0 0 1 0-21.55.52.52 0 0 1 .71.17.53.53 0 0 1-.17.72 11.59 11.59 0 0 0 0 19.78.52.52 0 0 1-.27 1Z"
    />
    <path
      className="Rf_svg__cls-2"
      d="M45.2 30.3a.52.52 0 0 1-.42-.22.51.51 0 0 1 .12-.72 17 17 0 0 0 0-27.62.52.52 0 1 1 .61-.85 18 18 0 0 1 0 29.31.5.5 0 0 1-.31.1ZM24.8 30.3a.47.47 0 0 1-.3-.1 18 18 0 0 1 0-29.31.51.51 0 0 1 .72.12.53.53 0 0 1-.12.73 17 17 0 0 0 0 27.62.51.51 0 0 1 .12.72.52.52 0 0 1-.42.22ZM53.24 69.21H16.76a.54.54 0 0 1-.43-.23.51.51 0 0 1 0-.49L34.3 24a.51.51 0 0 1 .48-.33.53.53 0 0 1 .48.32l18.46 44.5a.51.51 0 0 1 0 .49.54.54 0 0 1-.48.23Zm-35.71-1h34.93L34.78 25.57Z"
    />
  </svg>
);

export default SvgRf;

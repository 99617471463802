import * as React from "react";

const SvgCitizenVisitors = (props) => (
  <svg
    id="CitizenVisitors_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 700 700"
    style={{
      enableBackground: "new 0 0 700 700",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".CitizenVisitors_svg__st0{fill:#5a6e7f}"}</style>
    <path
      className="CitizenVisitors_svg__st0"
      d="M247.1 208.5c16.9 0 30.7-13.8 30.7-30.7s-13.8-30.7-30.7-30.7-30.7 13.8-30.7 30.7 13.8 30.7 30.7 30.7zM452.8 208.5c16.9 0 30.7-13.8 30.7-30.7s-13.8-30.7-30.7-30.7c-17 0-30.7 13.8-30.7 30.7s13.8 30.7 30.7 30.7zM160.1 251.5c19 0 34.5-15.5 34.5-34.5s-15.5-34.5-34.5-34.5c-19.1 0-34.5 15.5-34.5 34.5-.1 19 15.4 34.5 34.5 34.5zm75.9 108c.2-.6.2-1.3.1-2l-15.9-83.8c-1.3-7.8-7.9-13.3-15.9-13.3h-88.5c-8 0-14.6 5.5-15.9 13.2l-15.9 84c-.1.7-.1 1.3.1 1.9-.1.8-.2 1.5-.2 2.2 0 8.9 7.2 16.1 16.1 16.1 7.8 0 14.5-5.6 15.9-13.3l10-62.5-.1 199.4c0 8.9 6.3 16.1 15.2 16.1 6 0 13-3.3 13-8.1V401c0-2.1 3.5-3.7 5.6-3.7s5.6 1.7 5.6 3.7v107c0 5.6 7.3 9.5 13.8 9.5 8.9 0 15.7-7.2 15.7-16.1l-.4-199.4 9.9 62.6c1.4 7.7 8 13.2 15.8 13.2 8.9 0 16.1-7.2 16.1-16.1.1-.7 0-1.5-.1-2.2zM539.9 251.5c19 0 34.6-15.5 34.6-34.5s-15.5-34.5-34.6-34.5-34.5 15.5-34.5 34.5 15.4 34.5 34.5 34.5zm75.9 108c.2-.6.2-1.3.1-2L600 273.7c-1.3-7.8-7.9-13.3-15.9-13.3h-88.5c-8 0-14.6 5.5-15.9 13.2l-15.9 84c-.1.7-.1 1.3.1 1.9-.1.8-.2 1.5-.2 2.2 0 8.9 7.2 16.1 16.1 16.1 7.8 0 14.4-5.6 15.9-13.3l10-62.5-.1 199.4c0 8.9 6.8 16.1 15.6 16.1 6 0 13.5-3.3 13.5-8.1V400.9c0-2.1 3.5-3.7 5.6-3.7s5.6 1.7 5.6 3.7v107c0 5.6 6.8 9.5 13.3 9.5 8.9 0 15.4-7.2 15.4-16.1l-.3-199.3 9.9 62.6c1.4 7.7 8 13.2 15.7 13.2 8.9 0 16.1-7.2 16.1-16.1 0-.7-.1-1.5-.2-2.2zM350 223.4c24.5 0 44.4-19.9 44.4-44.4s-19.9-44.4-44.4-44.4-44.4 19.9-44.4 44.4 19.9 44.4 44.4 44.4zm97.5 138.8c.2-.8.3-1.7.1-2.5l-20.4-108.9c-1.6-10.1-10.2-18.3-20.4-18.3H293.1c-10.2 0-18.8 8.3-20.4 18.2l-20.4 108.6c-.2.9-.1 1.9.1 2.7-.1 1-.2 2.1-.2 3 0 11.5 9.3 20.8 20.7 20.8 10 0 18.6-7.1 20.4-17.1l12.9-80.3-.2 256.2c0 11.4 9.2 20.7 20.6 20.7 7.7 0 12.2-4.2 17.8-10.4V415.5c0-2.6 3-4.8 5.6-4.8s5.6 2.1 5.6 4.8V553c0 7.2 9.8 12.3 18.3 12.3 11.4 0 20.4-9.3 20.4-20.7l-.3-256.2 12.8 80.4c1.8 9.9 10.3 17 20.3 17 11.4 0 20.7-9.3 20.7-20.7 0-1-.1-1.9-.3-2.9z"
    />
  </svg>
);

export default SvgCitizenVisitors;
